import axios from "axios";
import { useContext } from 'react';
import { Context } from '../index';
import { jwtDecode } from "jwt-decode";

export const CheckAuth = async () => {
    
    const {user} = useContext(Context)

    const token = localStorage.getItem('token')

    axios.get(process.env.REACT_APP_API_URL + 'api/user/auth', {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
      .then((response) => {
        const token = jwtDecode(localStorage.getItem('token'))
        user.setUser(token)
        localStorage.setItem('token', response.data.token)
        user.setIsAuth(true)
        return true
      })
      .catch((error) => {
        if(error.response.status === 401 && localStorage.getItem('token') != undefined && localStorage.getItem('token').length > 2){
          localStorage.setItem('token', '')
          window.location.reload();
        }
        
        user.setUser('')
        user.setIsAuth(false)
        return false
    })
}