import React, { useContext } from 'react';
import { Context } from '../index';
import { NavLink } from 'react-router-dom';
import {observer} from 'mobx-react-lite'
import {useNavigate} from 'react-router-dom'

import logotype from '../assets/logo-vap-menu-header.png'
import search from '../assets/svg/search.svg'
import usericon from '../assets/svg/user-icon.svg'
import burger_menu from '../assets/svg/burger.svg'

const Header = observer(() => {
    const {user} = useContext(Context)

    const history = useNavigate()


    return (
        <div> 
            <nav className="container-fluid">
                <nav className="menu-header">
                    <nav className="row">
                        <nav className="col-7 col-lg-2">
                            <NavLink to={'/'}> <img className="logo-menu-header" src={logotype} alt="logo"/> </NavLink>
                        </nav>
                        <nav className="d-block d-sm-block d-md-block d-lg-none col-2 col-md-1 offset-md-2">
                            <img className="image-search-icon" src={search} alt="seacrh"/>
                        </nav>
                        <nav className="d-none d-sm-none d-md-none d-lg-block col-lg-3 offset-lg-5">
                            <img className="image-search-icon" src={search} alt="search"/><input type="text" className="input-search-header" placeholder="Найти курс"/>
                        </nav>
                        <nav className="d-none d-sm-none d-md-none d-lg-block col-lg-1">
                            {user.getUser.role === "USER" ? <p className="my-course-header-text"><NavLink to={'/'}>Мои курсы</NavLink></p> : <p onClick={() => history('/panel')} className="my-course-header-text" style={{cursor: "pointer"}}>Админ</p>}
                        </nav>
                        <nav className="d-none d-sm-none d-md-none d-lg-block col-lg-1">
                            <p className="my-course-header-text"><NavLink to={'/settings'}><img src={usericon} alt="user-icon"/></NavLink></p>
                        </nav>
                        <nav className="d-block d-sm-block d-md-block d-lg-none col-1 col-md-1">
                            <img className="image-search-icon" src={burger_menu}  alt="logo"/>
                        </nav>
                    </nav>
                </nav>
            </nav>
        </div>
    );
});

export default Header;