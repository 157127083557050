import React from 'react';
import { useLocation } from 'react-router-dom';
import {useEffect, useState} from 'react';
import { addLesson, deleteLesson, getLessonCategoriesList, updateLessonCategories } from '../http/deviceApi';
import { NavLink } from 'react-router-dom';

const AdminAddLessonList = () => {

    const navigate = useLocation()

    const [lessonCategoriesList, setlessonCategoriesList] = useState([])
    const [addNewLesson, setaddNewLesson] = useState(false)

    const [nameLesson, setnameLesson] = useState('')

    const [CategoryName, setCategoryName] = useState(navigate.state.data_categories.name)

    useEffect(()=>{
        getLessonCategoriesList(navigate.state.data_categories.id).then(data =>{
            setlessonCategoriesList(data)
        })
    }, [])

    const addModul = () =>{
        const formData = new FormData()
        formData.append('lesson_categories_id', navigate.state.lesson_id)
        formData.append('typel', '1')
        formData.append('name', nameLesson)
        //formData.append('author', authorCourse)
        //formData.append('img', file)
        addLesson(formData).finally(window.location.reload())
    }

    const delete_lesson = (id) => {
        let name_course = ""
        for( let i = 0; i < lessonCategoriesList.length; i++){
            if(lessonCategoriesList[i].id === id){
                name_course = lessonCategoriesList[i].name
                break
            }
        }
        const result = window.confirm("Вы уверены что хотите удалить урок: " + name_course);
        if(result){
            deleteLesson(id).finally(window.location.reload())
        }
    }

    const update_categories = () => {
        const result = window.confirm("Вы уверены что хотите обновить информацию о категории: " + navigate.state.data_categories.name);
        if(result){
            const formData = new FormData()
            formData.append('id', navigate.state.data_categories.id)
            formData.append('name', CategoryName)
            updateLessonCategories(formData).finally(window.location.reload())
        }
    }

    return (
        <>
            <h3>{navigate.state.data_categories.name}</h3>
            <nav className='row'>
                <nav className='col-12 col-md-6'>
                    <span>Название категории</span>
                    <input className='input-admin-module' type='text' defaultValue={CategoryName} onChange={(e)=>setCategoryName(e.target.value)}/>
                </nav>
            </nav>
            <button className='btn-update-curse-info' onClick={update_categories}>Сохранить</button>
            <button className='btn-update-curse-info' onClick={()=> setaddNewLesson(!addNewLesson)}>Создать новый урок</button>
            {
                addNewLesson === false ?
                <table className="iksweb">
                    <tbody>
                        <tr>
                            <th>Название урока</th>
                            <th>Дата создания</th>
                            <th>Статус</th>
                            <th>#</th>
                        </tr>
                        {
                            lessonCategoriesList.map(lessons  => 
                                <tr key={lessons.id}>
                                    <td><NavLink to={'./'+lessons.id} state={{data_categories: lessons, id_lesson: lessons.id}}>{lessons.name}</NavLink></td>
                                    <td>{lessons.createdAt.split('T')[0]}</td>
                                    <td><span style={{color: '#55C839'}}>Опубликован</span></td>
                                    <td style={{cursor: "pointer"}} onClick={()=> delete_lesson(lessons.id)}>Удалить</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                :
                <>
                    <nav className='row'>
                        <nav className='col-12 col-md-6'>
                            <span>Название урока</span>
                            <input className='input-admin-module' type='text' onChange={(e)=>setnameLesson(e.target.value)}/>
                        </nav>
                    </nav>
                    <button onClick={addModul} className='btn-update-curse-info'>Создать категорию</button>
                </>
            }
        </>
    );
};

export default AdminAddLessonList;