import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { NavLink } from 'react-router-dom';
import { Context } from '../index';



import './SettingsPage.css'

const SettingsPage = () => {
    const {user} = useContext(Context)

    const logOut = () =>{
        user.setUser({})
        user.setIsAuth(false)
        localStorage.setItem('token', '')
        window.location.replace('/');
    }

    return (
        <>
            <Header />
            <nav className="container-fluid">
                <nav className="row">
                    <nav className="col-12 col-lg-3">
                        <ul className="ul-list-left-setting-menu">
                            <NavLink to="./changepassword"><li>Редактировать профиль</li></NavLink>
                            <NavLink to="#" ><li>Управление подписками</li></NavLink>
                            <NavLink to="#"><li>История покупок</li></NavLink>
                        </ul>
                        <button className="btn-setting-page-exit" onClick={() => logOut()}>Выйти</button>
                    </nav>
                    <nav className="col-12 col-lg-9">
                        <Outlet />
                    </nav>
                </nav>
            </nav>
            <Footer />
        </>
    );
};

export default SettingsPage;