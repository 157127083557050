import React, {useEffect, useState} from 'react';

import {fetchLessonCategoriesList} from "../http/deviceApi";
import PercentIcon from '../assets/svg/over-75-white.svg'
import DocumentIcon from '../assets/svg/pdf-document-white.svg'
import { NavLink } from 'react-router-dom';

const LessonLeftBar = (id) => {
    const [activeLesson, setActiveLesson] = useState([])

    useEffect(() => {
        fetchLessonCategoriesList(id.id).then(data => {
            setActiveLesson(data)
        })
    }, [])
    
    return (
        <>
            {
                activeLesson.map(type => 
                <nav className="col-12" key={type.id}>
                    <nav className="lection-solo-line-lesson">
                        <nav className="row">
                            <nav className="col-2 ">
                                <img className="image-process-percent-lesson" src={PercentIcon} alt="PercentIcon"/>
                            </nav>
                            <nav className="col-2 block-absolute-right-line">
                                <nav className="block-right-line-lesson"></nav>
                                <img className="image-process-percent-lesson" src={DocumentIcon} alt="DocumentIcon"/>
                            </nav>
                            <nav className="col-8">
                                <p className="name-lection-solo-line-lesson">{type.name}</p>
                            </nav>
                        </nav>
                    </nav>
                </nav>
                )
            }
        </>
    );
};

export default LessonLeftBar;