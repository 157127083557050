import React from 'react';
import { useLocation } from 'react-router-dom';
import {useEffect, useState} from 'react';
import { addCategories, deleteLessonCategories, getLessonCategories, updateModule} from '../http/deviceApi';
import { NavLink } from 'react-router-dom';

const AdminAddLessonCategories = () => {

    const navigate = useLocation()
    const [addNewCategories, setaddNewCategories] = useState(false)

    const [lessonCategoriesList, setlessonCategoriesList] = useState([])

    const [nameCategories, setnameCategories] = useState('')


    const [nameModule, setnameModule] = useState(navigate.state.data_module.namemodule)
    const [authorModule, setauthorModule] = useState(navigate.state.data_module.author)
    const [File, setFile] = useState(null)



    useEffect(()=>{
        getLessonCategories(navigate.state.id_course, navigate.state.data_module.id).then(data =>{
            setlessonCategoriesList(data)
        })
    }, [])

    const addModul = () =>{
        console.log(navigate.state.module_id)
        const formData = new FormData()
        formData.append('courseid', navigate.state.id_course)
        formData.append('moduleid', navigate.state.module_id)
        formData.append('name', nameCategories)
        addCategories(formData).finally(window.location.reload())
    }

    const delete_categories = (id) => {
        let name_course = ""
        for( let i = 0; i < lessonCategoriesList.length; i++){
            if(lessonCategoriesList[i].id === id){
                name_course = lessonCategoriesList[i].name
                break
            }
        }
        const result = window.confirm("Вы уверены что хотите удалить категорию: " + name_course);
        if(result){
            deleteLessonCategories(id).finally(window.location.reload())
        }
    }

    const update_course = () => {
        const result = window.confirm("Вы уверены что хотите обновить информацию о модуле: " + nameModule);
        if(result){
            const formData = new FormData()
            formData.append('id', navigate.state.module_id)
            formData.append('namemodule', nameModule)
            formData.append('author', authorModule)
            formData.append('img', File)
            updateModule(formData).finally(window.location.reload())
        }
    }

    return (
        <> 
            <h3>{navigate.state.data_module.namemodule}</h3>
            <nav className='row'>
                <nav className='col-12 col-md-6'>
                    <span>Название модуля</span>
                    <input className='input-admin-module' type='text' defaultValue={nameModule} onChange={(e)=>setnameModule(e.target.value)}/>
                </nav>
                <nav className='col-12 col-md-6'>
                    <span>Автор модуля</span>
                    <input className='input-admin-module' type='text' defaultValue={authorModule} onChange={(e)=>setauthorModule(e.target.value)}/>
                </nav>
                <nav className='col-12 col-md-6'>
                    <span>Изображение</span>
                    <img src={`http://localhost:5000/${navigate.state.data_module.img}`} alt="img-icon" style={{width: "250px"}}/>
                    <input className='input-admin-module' type='file' onChange={(e)=>setFile(e.target.files[0])}/>
                </nav>
            </nav>
            <button className='btn-update-curse-info' onClick={update_course}>Сохранить</button>
            <button className='btn-update-curse-info' onClick={() => setaddNewCategories(!addNewCategories)}>Создать новую категорию</button>
            {
                addNewCategories === false ?
                    <table className="iksweb">
                    <tbody>
                        <tr>
                            <th>Название категории</th>
                            <th>Дата создания</th>
                            <th>Статус</th>
                            <th>#</th>
                        </tr>
                        {
                            lessonCategoriesList.map(lessons  => 
                                <tr key={lessons.id}>
                                    <td><NavLink to={'./'+lessons.id} state={{data_categories: lessons, lesson_id: lessons.id}}>{lessons.name}</NavLink></td>
                                    <td>{lessons.createdAt.split('T')[0]}</td>
                                    <td><span style={{color: '#55C839'}}>Опубликован</span></td>
                                    <td style={{cursor: "pointer"}} onClick={()=> delete_categories(lessons.id)}>Удалить</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                :
                <>
                    <nav className='row'>
                        <nav className='col-12 col-md-6'>
                            <span>Название категории</span>
                            <input className='input-admin-module' type='text' onChange={(e)=>setnameCategories(e.target.value)}/>
                        </nav>
                    </nav>
                    <button onClick={addModul} className='btn-update-curse-info'>Создать категорию</button>
                </>
            }
            
        </>
    );
};

export default AdminAddLessonCategories;