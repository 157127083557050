import React from 'react';

const Logotype = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="109" height="50" viewBox="0 0 109 50" fill="none">
                <g clipPath="url(#clip0_86_2015)">
                    <path d="M14.8544 33.8676C21.7517 33.8676 27.3431 28.277 27.3431 21.3806C27.3431 14.4842 21.7517 8.89355 14.8544 8.89355C7.95709 8.89355 2.36572 14.4842 2.36572 21.3806C2.36572 28.277 7.95709 33.8676 14.8544 33.8676Z" fill="url(#paint0_radial_86_2015)"/>
                    <path d="M12.0501 30.3628C17.3987 30.3628 21.7345 26.0276 21.7345 20.6797C21.7345 15.3319 17.3987 10.9966 12.0501 10.9966C6.70157 10.9966 2.36572 15.3319 2.36572 20.6797C2.36572 26.0276 6.70157 30.3628 12.0501 30.3628Z" fill="white"/>
                    <path d="M3.09191 37.7232C-1.09445 33.5081 -0.966321 27.0885 3.09191 23.0024C7.33598 27.2733 7.19807 33.5873 3.09191 37.7232Z" fill="#E7E7E7"/>
                    <path d="M0.556641 27.0523L0.563487 27.0386C1.09085 25.5197 1.95527 24.1398 3.09193 23.0024C10.8191 12.2504 16.6878 14.6435 29.2714 0C11.0861 9.20098 0.556641 7.07286 0.556641 27.0523Z" fill="#C0C0C0"/>
                    <path d="M22.4397 6.99951C21.2924 8.01076 20.2086 8.88411 19.1708 9.66259C22.1662 10.7681 24.6247 12.978 26.0418 15.8388C27.459 18.6995 27.7272 21.9942 26.7914 25.0465C26.0304 36.1095 16.5456 45.5032 2.37256 48.4998C45.1938 58.7081 46.4203 13.7232 22.4397 6.99951Z" fill="url(#paint1_linear_86_2015)"/>
                    <path d="M49.9409 34.8692V13.6575C52.3569 13.5362 54.5456 13.4753 56.5071 13.4746C58.9641 13.4746 60.7899 13.9636 61.9845 14.9416C63.1792 15.9196 63.7797 17.1982 63.7862 18.7773C63.7862 20.8911 62.96 22.4761 61.3077 23.5323V23.5929C62.2055 23.926 62.9753 24.5338 63.5075 25.3299C64.1061 26.1429 64.4054 27.1894 64.4054 28.4692C64.4054 30.6208 63.6415 32.2564 62.1137 33.3758C60.606 34.4934 58.6237 35.0521 56.1667 35.0521C53.2969 35.0521 51.2216 34.9912 49.9409 34.8692ZM56.8171 26.3079H54.8971V30.2981C55.4445 30.4304 56.0066 30.4919 56.5697 30.481C58.49 30.481 59.4502 29.7292 59.4502 28.2257C59.4502 26.9471 58.5725 26.3079 56.8171 26.3079ZM56.4454 18.0497C56.1559 18.0497 55.6398 18.0901 54.8971 18.1709V22.0418H56.5354C58.0632 22.0418 58.8272 21.3511 58.8272 19.9695C58.8272 19.3605 58.5898 18.893 58.1151 18.567C57.6632 18.2195 57.1066 18.0461 56.4454 18.0467V18.0497Z" fill="white"/>
                    <path d="M66.27 34.9297L75.2824 13.5938H79.7172L88.7297 34.9268H83.4282L81.6324 30.4466H73.0533L71.2565 34.9297H66.27ZM77.358 19.8441L74.9127 25.8783H79.7759L77.358 19.8441Z" fill="white"/>
                    <path d="M104.044 34.9297V18.1678H96.4557V34.9297H91.5005V13.5938H109V34.9297H104.044Z" fill="white"/>
                    <g opacity="0.9">
                    <path d="M49.9404 39.9935V37.7871C50.2779 37.7793 50.4852 37.7744 50.5625 37.7744C50.8168 37.7744 51.0014 37.824 51.1161 37.9231C51.2147 38.0147 51.2761 38.1394 51.2887 38.2734C51.3014 38.4073 51.2643 38.5413 51.1846 38.6497C51.1101 38.7365 51.0124 38.8003 50.9029 38.8336V38.8395C51.0335 38.8558 51.1546 38.9162 51.2462 39.0106C51.2896 39.0584 51.323 39.1143 51.3447 39.175C51.3663 39.2358 51.3758 39.3002 51.3724 39.3646C51.3785 39.4578 51.3608 39.5509 51.3212 39.6354C51.2815 39.7199 51.221 39.793 51.1455 39.8478C50.9683 39.9614 50.76 40.0168 50.5498 40.0062C50.3209 40.0062 50.1178 40.002 49.9404 39.9935ZM50.5821 38.9881H50.2163V39.7402C50.3141 39.749 50.4324 39.7529 50.5654 39.7529C50.7029 39.7627 50.84 39.7284 50.9567 39.6551C51.0028 39.6197 51.0396 39.5736 51.0638 39.5208C51.0881 39.4679 51.099 39.41 51.0956 39.3519C51.0981 39.2996 51.0869 39.2475 51.0631 39.2008C51.0392 39.1541 51.0035 39.1145 50.9596 39.0859C50.8468 39.0153 50.715 38.9811 50.5821 38.9881ZM50.5557 38.0277C50.4911 38.0277 50.3777 38.0277 50.2163 38.0365V38.7514H50.5782C50.6901 38.756 50.8001 38.7214 50.8892 38.6536C50.9306 38.6218 50.9637 38.5805 50.9857 38.5332C51.0076 38.4858 51.0178 38.4339 51.0154 38.3818C51.0154 38.1421 50.8618 38.0277 50.5557 38.0277Z" fill="white"/>
                    <path d="M52.0917 39.9994H51.8335V38.5742H52.0917V39.6329L52.9339 38.5742H53.1931V39.9994H52.9339V38.9378L52.0917 39.9994Z" fill="white"/>
                    <path d="M53.6646 40.6292V38.5754H53.9237V38.7427C53.9818 38.6796 54.0538 38.631 54.134 38.6008C54.2242 38.563 54.321 38.5438 54.4187 38.5441C54.51 38.5399 54.6011 38.5558 54.6856 38.5906C54.7701 38.6254 54.8459 38.6783 54.9077 38.7456C55.0344 38.8878 55.1007 39.0737 55.0926 39.2639C55.0957 39.3646 55.0786 39.4649 55.0425 39.5589C55.0064 39.6529 54.952 39.7388 54.8823 39.8116C54.8112 39.8841 54.7258 39.941 54.6315 39.9785C54.5372 40.0161 54.436 40.0335 54.3346 40.0297C54.1911 40.037 54.0485 40.0031 53.9237 39.9319V40.6312L53.6646 40.6292ZM54.3492 38.7798C54.2688 38.7778 54.189 38.7943 54.116 38.8283C54.043 38.8622 53.9789 38.9126 53.9286 38.9754V39.6806C54.0451 39.7556 54.1814 39.7941 54.3199 39.7911C54.3877 39.7944 54.4554 39.7833 54.5185 39.7583C54.5816 39.7333 54.6386 39.6952 54.6857 39.6463C54.7325 39.5963 54.7689 39.5376 54.7929 39.4735C54.8169 39.4094 54.828 39.3411 54.8256 39.2727C54.8316 39.1419 54.7863 39.0138 54.6994 38.9158C54.6536 38.8687 54.5982 38.8321 54.5369 38.8086C54.4755 38.7852 54.4098 38.7753 54.3443 38.7798H54.3492Z" fill="white"/>
                    <path d="M55.2222 38.8264V38.5732H56.5505V38.8264H56.0154V39.9994H55.7523V38.8264H55.2222Z" fill="white"/>
                    <path d="M58.1978 38.5742L57.6109 40.0001C57.5453 40.1798 57.4526 40.3483 57.336 40.4999C57.3015 40.5412 57.2585 40.5745 57.2098 40.5974C57.1612 40.6204 57.1081 40.6325 57.0543 40.6329C56.9557 40.6308 56.8598 40.5998 56.7785 40.5439L56.8626 40.3483C56.9131 40.3729 56.9679 40.3875 57.024 40.3913C57.0548 40.391 57.0852 40.384 57.113 40.3708C57.1409 40.3576 57.1656 40.3386 57.1854 40.3151C57.2491 40.2421 57.2997 40.1587 57.335 40.0686L57.3673 39.9923L56.668 38.5703H56.978L57.4064 39.474L57.4935 39.6891C57.5131 39.6197 57.5356 39.5464 57.562 39.471L57.8984 38.5703L58.1978 38.5742Z" fill="white"/>
                    <path d="M58.5394 38.8745L58.4709 38.6722C58.6187 38.5846 58.7881 38.5399 58.96 38.5431C59.1605 38.5431 59.3043 38.591 59.3903 38.6878C59.4841 38.8115 59.5293 38.9652 59.5175 39.1199V39.9996H59.273V39.8168C59.2165 39.8849 59.1449 39.9389 59.0639 39.9746C58.983 40.0102 58.8948 40.0265 58.8064 40.0221C58.7474 40.0247 58.6884 40.0157 58.6328 39.9956C58.5773 39.9755 58.5262 39.9446 58.4826 39.9048C58.4409 39.8626 58.4084 39.8123 58.3872 39.7569C58.366 39.7016 58.3565 39.6424 58.3594 39.5832C58.3565 39.5197 58.3689 39.4564 58.3954 39.3986C58.422 39.3408 58.4619 39.2901 58.512 39.2508C58.6274 39.1674 58.7677 39.1253 58.9101 39.1316C59.037 39.1226 59.1636 39.1526 59.273 39.2176C59.2802 39.1012 59.258 38.9849 59.2084 38.8794C59.1781 38.839 59.1376 38.8074 59.0909 38.788C59.0443 38.7686 58.9934 38.762 58.9433 38.7689C58.8021 38.7712 58.6636 38.8075 58.5394 38.8745ZM58.8954 39.7904C58.968 39.7905 59.0396 39.7742 59.105 39.7427C59.1704 39.7113 59.2278 39.6654 59.273 39.6086V39.4288C59.177 39.3627 59.0627 39.3285 58.9463 39.331C58.8683 39.3284 58.7913 39.3492 58.7252 39.3906C58.6962 39.4092 58.6726 39.435 58.6568 39.4656C58.641 39.4961 58.6336 39.5303 58.6352 39.5646C58.6337 39.5953 58.639 39.6259 58.6507 39.6542C58.6623 39.6826 58.6801 39.7081 58.7027 39.7288C58.7576 39.7716 58.8259 39.7934 58.8954 39.7904Z" fill="white"/>
                    <path d="M60.9491 39.9994V38.8264H60.4375L60.4209 39.0659C60.4109 39.2039 60.3916 39.3411 60.3632 39.4765C60.3438 39.5713 60.3132 39.6635 60.2722 39.7511C60.2442 39.8127 60.2035 39.8676 60.1529 39.9124C60.1104 39.9494 60.0608 39.9773 60.0071 39.9945C59.9508 40.0081 59.893 40.0147 59.835 40.0141L59.8154 39.7668C59.8406 39.7694 59.8659 39.7664 59.8898 39.758C59.9223 39.7431 59.9513 39.7214 59.9749 39.6944C60.0104 39.6579 60.0389 39.6152 60.059 39.5683C60.0901 39.4976 60.1127 39.4235 60.1265 39.3474C60.1499 39.238 60.164 39.1268 60.1685 39.0151L60.1949 38.5732H61.2083V39.9994H60.9491Z" fill="white"/>
                    <path d="M61.6758 39.9994V38.5742H61.935V39.0004H62.2001C62.4074 39.0004 62.5551 39.0473 62.6441 39.1392C62.6883 39.1861 62.7229 39.2412 62.7457 39.3014C62.7685 39.3616 62.7792 39.4258 62.7771 39.4901C62.7789 39.557 62.7668 39.6236 62.7417 39.6856C62.7167 39.7476 62.6791 39.8039 62.6314 39.8508C62.5336 39.9479 62.3791 39.9962 62.1678 39.9955L61.6758 39.9994ZM62.1511 39.2419H61.935V39.7629H62.1482C62.3836 39.7629 62.5013 39.6765 62.5013 39.5038C62.5013 39.3311 62.3846 39.2425 62.1511 39.2379V39.2419Z" fill="white"/>
                    <path d="M64.1921 39.9994V39.3992H63.3813V39.9994H63.1221V38.5742H63.3813V39.1607H64.1902V38.5742H64.4494V39.9994H64.1921Z" fill="white"/>
                    <path d="M64.9989 38.8745L64.9304 38.6722C65.0783 38.5846 65.2476 38.5399 65.4195 38.5431C65.62 38.5431 65.7638 38.591 65.8508 38.6878C65.9458 38.8124 65.9917 38.9676 65.98 39.1238V39.9996H65.7296V39.8168C65.6733 39.8849 65.6019 39.9389 65.5211 39.9746C65.4403 40.0102 65.3522 40.0265 65.264 40.0221C65.2048 40.0249 65.1456 40.0159 65.0899 39.9958C65.0342 39.9757 64.983 39.9448 64.9392 39.9048C64.8977 39.8626 64.8653 39.8122 64.8443 39.7568C64.8232 39.7015 64.8139 39.6423 64.817 39.5832C64.8139 39.5197 64.8261 39.4565 64.8525 39.3987C64.8788 39.3408 64.9186 39.2902 64.9686 39.2508C65.084 39.1674 65.2243 39.1253 65.3667 39.1316C65.4936 39.1226 65.6202 39.1526 65.7296 39.2176C65.7369 39.1012 65.7147 38.9849 65.665 38.8794C65.6347 38.839 65.5942 38.8074 65.5476 38.788C65.5009 38.7686 65.45 38.762 65.3999 38.7689C65.2598 38.7717 65.1223 38.8079 64.9989 38.8745ZM65.3549 39.7904C65.427 39.7901 65.4981 39.7736 65.5629 39.7421C65.6278 39.7106 65.6847 39.665 65.7296 39.6086V39.4288C65.6336 39.3627 65.5193 39.3285 65.4029 39.331C65.3252 39.3286 65.2486 39.3494 65.1828 39.3906C65.1536 39.409 65.1298 39.4348 65.1139 39.4654C65.0979 39.4959 65.0903 39.5302 65.0918 39.5646C65.0903 39.5953 65.0956 39.6259 65.1073 39.6542C65.1189 39.6826 65.1367 39.7081 65.1593 39.7288C65.215 39.7722 65.2844 39.7941 65.3549 39.7904Z" fill="white"/>
                    <path d="M67.0481 38.5742H67.5009V39.9994H67.2417V39.5253H66.96L66.6089 39.9994H66.2949L66.7155 39.4628C66.6237 39.4413 66.5422 39.3886 66.4849 39.3137C66.4276 39.2389 66.3982 39.1464 66.4015 39.0522C66.4 38.9877 66.4133 38.9238 66.4402 38.8652C66.4672 38.8065 66.5071 38.7549 66.5571 38.714C66.6568 38.6208 66.8205 38.5742 67.0481 38.5742ZM66.6725 39.0542C66.6714 39.0868 66.6785 39.1193 66.6932 39.1485C66.7079 39.1777 66.7297 39.2027 66.7566 39.2213C66.8101 39.2633 66.8764 39.2857 66.9444 39.2849H67.2378V38.8108H67.0021C66.9122 38.804 66.8227 38.8286 66.7488 38.8802C66.7236 38.9014 66.7036 38.9281 66.6904 38.9583C66.6771 38.9885 66.671 39.0213 66.6725 39.0542Z" fill="white"/>
                    <path d="M68.5166 40.0003L69.4947 37.7754H69.7305L70.7086 40.0003H70.4151L70.1217 39.3304H69.0829L68.7954 40.0003H68.5166ZM69.6013 38.1324L69.1935 39.0781H70.0092L69.6013 38.1324Z" fill="white"/>
                    <path d="M72.2598 38.5742L71.6221 39.2536L72.3215 39.9994H71.9615L71.3042 39.2692L71.9194 38.5742H72.2598ZM70.9883 39.9955V38.5742H71.2475V39.9994L70.9883 39.9955Z" fill="white"/>
                    <path d="M72.6741 38.8745L72.6056 38.6722C72.7534 38.5846 72.9228 38.5399 73.0946 38.5431C73.2952 38.5431 73.4389 38.591 73.525 38.6878C73.6188 38.8115 73.664 38.9652 73.6522 39.1199V39.9996H73.4057V39.8168C73.3491 39.8848 73.2776 39.9387 73.1966 39.9744C73.1156 40.01 73.0275 40.0263 72.9391 40.0221C72.8199 40.0273 72.7035 39.9852 72.6154 39.9048C72.5739 39.8625 72.5415 39.8121 72.5203 39.7568C72.4991 39.7015 72.4895 39.6424 72.4921 39.5832C72.4894 39.5197 72.5019 39.4564 72.5284 39.3987C72.5549 39.3409 72.5948 39.2902 72.6447 39.2508C72.7605 39.1672 72.9011 39.1252 73.0438 39.1316C73.1709 39.1242 73.2972 39.156 73.4057 39.2225C73.413 39.1061 73.3908 38.9898 73.3411 38.8843C73.3109 38.8439 73.2703 38.8123 73.2237 38.7929C73.1771 38.7735 73.1261 38.7669 73.0761 38.7738C72.9359 38.7749 72.7981 38.8095 72.6741 38.8745ZM73.0301 39.7904C73.1024 39.7901 73.1737 39.7735 73.2388 39.7419C73.3038 39.7102 73.3608 39.6644 73.4057 39.6076V39.4278C73.3098 39.3616 73.1955 39.3274 73.079 39.33C73.0013 39.3273 72.9246 39.348 72.8589 39.3897C72.8297 39.4081 72.806 39.4339 72.79 39.4644C72.774 39.495 72.7664 39.5292 72.768 39.5636C72.7665 39.5943 72.7717 39.6249 72.7834 39.6533C72.7951 39.6816 72.8128 39.7071 72.8354 39.7279C72.891 39.7716 72.9604 39.7938 73.0311 39.7904H73.0301Z" fill="white"/>
                    <path d="M73.8857 40.3435V39.757H74.0677C74.1337 39.6627 74.1809 39.5565 74.2066 39.4442C74.2526 39.2822 74.2808 39.1157 74.2907 38.9476L74.3132 38.5723H75.3079V39.757H75.5505V40.3435H75.3079V39.9994H74.1342V40.3474L73.8857 40.3435ZM74.5401 39.0483C74.5284 39.198 74.5012 39.3461 74.4589 39.4901C74.4356 39.5857 74.3962 39.6765 74.3425 39.759H75.0487V38.8108H74.5597L74.5401 39.0483Z" fill="white"/>
                    <path d="M76.9832 39.642L77.0644 39.8375C76.9117 39.9662 76.7162 40.0329 76.5166 40.0242C76.3085 40.0343 76.1048 39.9616 75.9503 39.8219C75.8784 39.752 75.8221 39.6677 75.7852 39.5746C75.7482 39.4814 75.7315 39.3814 75.7361 39.2813C75.7328 39.184 75.7488 39.087 75.7832 38.9959C75.8176 38.9048 75.8698 38.8214 75.9366 38.7505C76.009 38.6792 76.0956 38.6239 76.1909 38.5882C76.2861 38.5525 76.3877 38.5372 76.4892 38.5433C76.6682 38.538 76.8419 38.6037 76.9724 38.7261C77.0367 38.7854 77.0874 38.8578 77.1212 38.9384C77.1549 39.0191 77.1709 39.106 77.1681 39.1934C77.169 39.2508 77.1637 39.3081 77.1524 39.3644H76.0276C76.03 39.4243 76.0449 39.4829 76.0711 39.5368C76.0974 39.5906 76.1345 39.6384 76.1802 39.6772C76.2818 39.7556 76.4079 39.7958 76.5362 39.7906C76.6987 39.7999 76.8586 39.7467 76.9832 39.642ZM76.4746 38.7799C76.3592 38.7751 76.2463 38.8135 76.1577 38.8874C76.0759 38.9559 76.0237 39.0532 76.0119 39.1591H76.8922C76.8914 39.1068 76.88 39.0552 76.8589 39.0074C76.8377 38.9595 76.8071 38.9164 76.769 38.8805C76.6872 38.8109 76.5819 38.7749 76.4746 38.7799Z" fill="white"/>
                    <path d="M77.5156 39.9994V38.5742H77.7523L78.3118 39.279L78.8429 38.5742H79.0738V39.9994H78.8146V38.9633L78.3089 39.6241H78.2991L77.7748 38.9633V39.9994H77.5156Z" fill="white"/>
                    <path d="M79.8012 39.9994H79.542V38.5742H79.8012V39.6329L80.6434 38.5742H80.9026V39.9994H80.6434V38.9378L79.8012 39.9994Z" fill="white"/>
                    <path d="M82.012 38.5742H82.4658V39.9994H82.2066V39.5253H81.9249L81.5679 39.9994H81.2539L81.6755 39.4628C81.5835 39.4416 81.5017 39.3891 81.4444 39.3141C81.3871 39.2391 81.3577 39.1465 81.3615 39.0522C81.36 38.9877 81.3732 38.9238 81.4002 38.8652C81.4271 38.8065 81.4671 38.7549 81.517 38.714C81.6207 38.6208 81.7857 38.5742 82.012 38.5742ZM81.6364 39.0542C81.6353 39.0868 81.6424 39.1193 81.6571 39.1485C81.6718 39.1777 81.6936 39.2027 81.7205 39.2213C81.774 39.2633 81.8403 39.2857 81.9083 39.2849H82.2066V38.8108H81.966C81.8763 38.8036 81.787 38.8282 81.7136 38.8802C81.6883 38.9014 81.6682 38.928 81.6548 38.9582C81.6414 38.9884 81.6351 39.0212 81.6364 39.0542Z" fill="white"/>
                    <path d="M85.2778 40.0003V38.0443H84.0473V40.0003H83.7715V37.7842H85.5526V40.0003H85.2778Z" fill="white"/>
                    <path d="M87.2477 39.6082L87.371 39.791C87.3076 39.8696 87.2251 39.9306 87.1313 39.9679C87.0293 40.0087 86.9204 40.0293 86.8105 40.0285C86.6025 40.0376 86.3993 39.965 86.2442 39.8262C86.1727 39.7561 86.1168 39.6717 86.0801 39.5786C86.0434 39.4854 86.0266 39.3857 86.0309 39.2857C86.0286 39.188 86.046 39.091 86.0821 39.0003C86.1183 38.9095 86.1724 38.8271 86.2412 38.7578C86.3095 38.6876 86.3916 38.6324 86.4824 38.5955C86.5731 38.5587 86.6705 38.541 86.7684 38.5437C87.0237 38.5437 87.2145 38.6053 87.3426 38.7295L87.2223 38.925C87.1072 38.8238 86.9577 38.7707 86.8046 38.7764C86.739 38.7757 86.6739 38.7883 86.6133 38.8133C86.5526 38.8383 86.4976 38.8753 86.4515 38.922C86.3572 39.0194 86.3044 39.1496 86.3044 39.2852C86.3044 39.4207 86.3572 39.5509 86.4515 39.6483C86.5634 39.7464 86.7091 39.7971 86.8578 39.7896C87.0064 39.7821 87.1463 39.7171 87.2477 39.6082Z" fill="white"/>
                    <path d="M87.9653 39.9994H87.7061V38.5742H87.9653V39.6329L88.8074 38.5742H89.0666V39.9994H88.8074V38.9378L87.9653 39.9994Z" fill="white"/>
                    <path d="M90.8329 38.5742L90.3115 39.2585L90.8671 39.9994H90.5492L90.1218 39.4246L89.6865 39.9994H89.3853L89.9359 39.2761L89.4175 38.5781H89.7354L90.1267 39.1148L90.5277 38.5781L90.8329 38.5742Z" fill="white"/>
                    <path d="M91.2155 39.8188C91.1453 39.7492 91.0901 39.6659 91.0535 39.5741C91.0168 39.4823 90.9994 39.3839 91.0023 39.2851C90.9992 39.1867 91.0165 39.0887 91.0532 38.9973C91.0899 38.906 91.1452 38.8232 91.2155 38.7543C91.2908 38.6833 91.3795 38.6281 91.4764 38.5918C91.5733 38.5555 91.6765 38.539 91.7799 38.5432C91.8828 38.5388 91.9854 38.5553 92.0817 38.5916C92.178 38.6278 92.266 38.6832 92.3404 38.7543C92.4115 38.8228 92.4675 38.9054 92.5047 38.9968C92.5419 39.0882 92.5596 39.1864 92.5566 39.2851C92.5594 39.3842 92.5417 39.4827 92.5045 39.5746C92.4673 39.6665 92.4114 39.7496 92.3404 39.8188C92.2656 39.8892 92.1775 39.944 92.0813 39.98C91.985 40.0159 91.8826 40.0322 91.7799 40.028C91.6767 40.0321 91.5737 40.0157 91.4768 39.9798C91.3799 39.9439 91.2911 39.8892 91.2155 39.8188ZM91.2772 39.2851C91.2749 39.352 91.2861 39.4186 91.3101 39.4811C91.3341 39.5436 91.3705 39.6006 91.417 39.6487C91.4646 39.6959 91.5213 39.7329 91.5837 39.7573C91.6462 39.7816 91.7129 39.7929 91.7799 39.7905C91.846 39.7928 91.9119 39.7817 91.9736 39.7578C92.0353 39.734 92.0916 39.6978 92.1389 39.6517C92.2307 39.5522 92.2816 39.4218 92.2816 39.2866C92.2816 39.1513 92.2307 39.0209 92.1389 38.9215C92.092 38.8744 92.0359 38.8376 91.9742 38.8132C91.9124 38.7888 91.8463 38.7774 91.7799 38.7797C91.7131 38.7778 91.6467 38.7896 91.5846 38.8144C91.5225 38.8391 91.4662 38.8763 91.4191 38.9237C91.372 38.971 91.3351 39.0275 91.3107 39.0897C91.2863 39.1519 91.2749 39.2184 91.2772 39.2851Z" fill="white"/>
                    <path d="M92.6899 38.8264V38.5732H94.0173V38.8264H93.4832V39.9994H93.224V38.8264H92.6899Z" fill="white"/>
                    <path d="M95.4031 39.6417L95.4843 39.8372C95.324 39.9609 95.1265 40.0267 94.924 40.0239C94.7215 40.0211 94.526 39.9497 94.3692 39.8216C94.2976 39.7516 94.2416 39.6672 94.2048 39.5741C94.1681 39.4809 94.1514 39.381 94.156 39.281C94.1518 39.184 94.167 39.0871 94.2005 38.996C94.2341 38.9049 94.2855 38.8213 94.3516 38.7502C94.4235 38.6784 94.5096 38.6225 94.6045 38.5862C94.6994 38.5498 94.8009 38.5337 94.9023 38.5391C94.9908 38.5364 95.0789 38.5511 95.1616 38.5825C95.2444 38.6139 95.3201 38.6612 95.3845 38.7219C95.4489 38.7811 95.4998 38.8535 95.5335 38.9341C95.5673 39.0148 95.5832 39.1018 95.5802 39.1891C95.5813 39.2466 95.5757 39.304 95.5635 39.3602H94.4475C94.45 39.42 94.4648 39.4787 94.491 39.5325C94.5173 39.5864 94.5544 39.6342 94.6001 39.673C94.7017 39.7516 94.8278 39.7917 94.9561 39.7864C95.1181 39.7967 95.2779 39.7449 95.4031 39.6417ZM94.8945 38.7796C94.7789 38.7748 94.6656 38.8131 94.5766 38.8871C94.4952 38.9558 94.4431 39.053 94.4309 39.1588H95.3112C95.311 39.1065 95.3 39.0548 95.279 39.0069C95.2579 38.9589 95.2273 38.9158 95.1889 38.8802C95.107 38.8107 95.0018 38.7748 94.8945 38.7796Z" fill="white"/>
                    <path d="M97.1615 38.5742L96.6402 39.2585L97.1938 39.9945H96.8769L96.4484 39.4198L96.0151 39.9994H95.7139L96.2646 39.2761L95.7461 38.5781H96.0631L96.4543 39.1148L96.8563 38.5781L97.1615 38.5742Z" fill="white"/>
                    <path d="M98.5769 39.9994V39.3992H97.768V39.9994H97.5088V38.5742H97.768V39.1607H98.5769V38.5742H98.8361V39.9994H98.5769Z" fill="white"/>
                    <path d="M99.4221 39.8188C99.3515 39.7494 99.2961 39.6662 99.2592 39.5743C99.2224 39.4825 99.2049 39.384 99.2079 39.2851C99.2047 39.1866 99.2221 39.0885 99.259 38.9972C99.2959 38.9058 99.3514 38.8231 99.4221 38.7543C99.4971 38.6833 99.5856 38.628 99.6824 38.5917C99.7792 38.5554 99.8822 38.5389 99.9855 38.5432C100.088 38.5387 100.191 38.5552 100.287 38.5915C100.383 38.6277 100.471 38.6832 100.545 38.7543C100.616 38.8226 100.673 38.9052 100.71 38.9966C100.748 39.0881 100.765 39.1864 100.762 39.2851C100.765 39.3843 100.747 39.483 100.71 39.5748C100.672 39.6667 100.616 39.7498 100.545 39.8188C100.47 39.8893 100.383 39.9442 100.286 39.9801C100.19 40.016 100.088 40.0323 99.9855 40.028C99.8824 40.032 99.7796 40.0155 99.6829 39.9796C99.5862 39.9437 99.4976 39.8891 99.4221 39.8188ZM99.4837 39.2851C99.4812 39.3519 99.4922 39.4186 99.5161 39.4811C99.54 39.5435 99.5762 39.6006 99.6226 39.6487C99.6702 39.696 99.7269 39.7329 99.7893 39.7573C99.8517 39.7817 99.9185 39.793 99.9855 39.7905C100.052 39.7928 100.118 39.7817 100.179 39.7579C100.241 39.734 100.297 39.6979 100.344 39.6517C100.436 39.5522 100.487 39.4219 100.487 39.2866C100.487 39.1513 100.436 39.021 100.344 38.9215C100.298 38.8745 100.242 38.8376 100.18 38.8132C100.118 38.7889 100.052 38.7775 99.9855 38.7797C99.919 38.7777 99.8528 38.7892 99.7909 38.8135C99.729 38.8379 99.6727 38.8746 99.6255 38.9215C99.5782 38.9691 99.5411 39.026 99.5167 39.0885C99.4923 39.1511 99.4811 39.218 99.4837 39.2851Z" fill="white"/>
                    <path d="M102.076 39.9994V38.8264H101.564L101.549 39.0659C101.539 39.2039 101.519 39.3411 101.49 39.4765C101.471 39.5713 101.44 39.6635 101.399 39.7511C101.371 39.8127 101.33 39.8676 101.28 39.9124C101.237 39.9494 101.188 39.9773 101.134 39.9945C101.078 40.0081 101.02 40.0147 100.962 40.0141L100.942 39.7668C100.968 39.7694 100.993 39.7664 101.017 39.758C101.049 39.7431 101.078 39.7214 101.102 39.6944C101.137 39.6579 101.166 39.6152 101.186 39.5683C101.217 39.4976 101.24 39.4235 101.253 39.3474C101.277 39.238 101.291 39.1268 101.295 39.0151L101.322 38.5732H102.335V39.9994H102.076Z" fill="white"/>
                    <path d="M102.919 39.8188C102.849 39.7494 102.793 39.6661 102.756 39.5743C102.719 39.4825 102.702 39.384 102.705 39.2851C102.702 39.1866 102.719 39.0885 102.756 38.9971C102.793 38.9057 102.848 38.823 102.919 38.7543C102.994 38.6833 103.083 38.628 103.179 38.5918C103.276 38.5555 103.379 38.539 103.483 38.5432C103.585 38.5388 103.688 38.5553 103.784 38.5916C103.881 38.6278 103.969 38.6832 104.043 38.7543C104.114 38.8226 104.171 38.9051 104.208 38.9966C104.246 39.088 104.263 39.1863 104.26 39.2851C104.263 39.3842 104.245 39.4829 104.208 39.5748C104.171 39.6667 104.114 39.7498 104.043 39.8188C103.968 39.8892 103.88 39.944 103.784 39.98C103.688 40.0159 103.585 40.0322 103.483 40.028C103.379 40.032 103.277 40.0155 103.18 39.9796C103.083 39.9437 102.995 39.8891 102.919 39.8188ZM102.981 39.2851C102.978 39.3519 102.989 39.4186 103.013 39.481C103.037 39.5435 103.073 39.6005 103.12 39.6487C103.218 39.7366 103.346 39.7835 103.477 39.7797C103.609 39.7759 103.734 39.7217 103.827 39.6284C103.92 39.535 103.973 39.4096 103.976 39.278C103.979 39.1465 103.931 39.0188 103.843 38.9215C103.772 38.8494 103.681 38.8003 103.582 38.7805C103.483 38.7606 103.381 38.771 103.288 38.8102C103.195 38.8494 103.116 38.9157 103.061 39.0004C103.006 39.0851 102.978 39.1843 102.981 39.2851Z" fill="white"/>
                    <path d="M104.635 39.9994V38.5742H105.558V38.8108H104.894V39.9994H104.635Z" fill="white"/>
                    <path d="M106.068 39.9994H105.809V38.5742H106.068V39.6329L106.91 38.5742H107.168V39.9994H106.909V38.9378L106.068 39.9994Z" fill="white"/>
                    <path d="M107.898 40.0002H107.639V38.5743H107.898V39.6335L108.741 38.5743H109V40.0002H108.741V38.9381L107.898 40.0002ZM107.847 37.8486H108.089C108.103 38.0149 108.186 38.098 108.339 38.098C108.483 38.098 108.563 38.0149 108.575 37.8486H108.818C108.819 37.9076 108.808 37.9662 108.785 38.0207C108.763 38.0751 108.729 38.1241 108.686 38.1645C108.591 38.2504 108.466 38.2956 108.339 38.2907C108.206 38.2987 108.076 38.2545 107.976 38.1675C107.933 38.1264 107.899 38.0768 107.877 38.0218C107.855 37.9669 107.844 37.9079 107.847 37.8486Z" fill="white"/>
                    </g>
                </g>
                <defs>
                    <radialGradient id="paint0_radial_86_2015" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.6395 22.2086) rotate(90) scale(12.5262 12.5279)">
                    <stop stopColor="#700029"/>
                    <stop offset="1" stopColor="#FB1D6F"/>
                    </radialGradient>
                    <linearGradient id="paint1_linear_86_2015" x1="20.237" y1="50" x2="20.237" y2="6.99951" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="white"/>
                    </linearGradient>
                    <clipPath id="clip0_86_2015">
                    <rect width="109" height="50" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default Logotype;
