import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';


import UserStore from './store/UserStore';
import RouterApp from './components/RouterApp';
import CourseStore from './store/CourseStore';


export const Context = createContext(null)




ReactDOM.createRoot(document.getElementById("root")).render(
  <Context.Provider value={{
    user: new UserStore(),
    course: new CourseStore()
  }}>
      <RouterApp />    
  </Context.Provider>
);