import React from 'react';
import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { deleteModule, getModuleAdmin, updateCourse } from '../http/deviceApi';
import { NavLink } from 'react-router-dom';
import { addModules } from '../http/deviceApi';

const AdminAddModules = () => {
    const navigate = useLocation()
    
    const [moduleList, setmoduleList] = useState([])
    const [addNewModule, setaddNewModule] = useState(false)

    const [nameCourse, setnameCourse] = useState('')
    const [authorCourse, setauthorCourse] = useState('')
    const [file, setFile] = useState(null)

    const [courseName, setcourseName] = useState(navigate.state.data_course.name)
    const [authorName, setauthorName] = useState(navigate.state.data_course.author)

    useEffect(()=>{
        getModuleAdmin(navigate.state.id_course).then(data =>{
            setmoduleList(data)
        })
    }, [])

    const addModul = () =>{
        const formData = new FormData()
        formData.append('courseid', navigate.state.id_course)
        formData.append('name', nameCourse)
        formData.append('author', authorCourse)
        formData.append('img', file)
        console.log('go')
        addModules(formData).finally(window.location.reload())
    }

    const delete_module = (id) => {
        let name_course = ""
        for( let i = 0; i < moduleList.length; i++){
            if(moduleList[i].id === id){
                name_course = moduleList[i].namemodule
                break
            }
        }
        const result = window.confirm("Вы уверены что хотите удалить модуль: " + name_course);
        if(result){
            deleteModule(id).finally(window.location.reload())
        }
    }

    const update_course = () => {
        const result = window.confirm("Вы уверены что хотите обновить информацию о курсе: " + navigate.state.data_course.name);
        if(result){
            const formData = new FormData()
            formData.append('id', navigate.state.data_course.id)
            formData.append('name', courseName)
            formData.append('author', authorName)
            updateCourse(formData).finally(window.location.reload())
        }
    }

    return (
        <>
            <h3>{navigate.state.data_course.name}</h3>
            <nav className='row'>
                <nav className='col-12 col-md-6'>
                    <span>Название курса</span>
                    <input className='input-admin-module' type='text' defaultValue={courseName} onChange={(e)=>setcourseName(e.target.value)}/>
                </nav>
                <nav className='col-12 col-md-6'>
                    <span>Автор курса</span>
                    <input className='input-admin-module' type='text' defaultValue={authorName} onChange={(e)=>setauthorName(e.target.value)}/>
                </nav>
            </nav>
            <button className='btn-update-curse-info' onClick={update_course}>Сохранить</button>
            <button className='btn-update-curse-info' onClick={()=> setaddNewModule(!addNewModule)}>{addNewModule ? 'Отменить' : 'Создать новый модуль'}</button>
            {
                addNewModule === false?
                <table className="iksweb">
                    <tbody>
                        <tr>
                            <th>Название модуля</th>
                            <th>Автор</th>
                            <th>Дата создания</th>
                            <th>Статус</th>
                            <th>#</th>
                        </tr>
                        {
                            moduleList.map(module_c => 
                                <tr key={module_c.id}>
                                    <td><NavLink to={'./'+module_c.id} state={{id_course: navigate.state.data_course.id, data_module: module_c, module_id: module_c.id}}>{module_c.namemodule}</NavLink></td>
                                    <td>{module_c.author}</td>
                                    <td>{module_c.createdAt.split('T')[0]}</td>
                                    <td><span style={{color: '#55C839'}}>Опубликован</span></td>
                                    <td style={{cursor: "pointer"}} onClick={() => delete_module(module_c.id)}>Удалить</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                :
                <>
                    <h4>Создание модуля</h4>
                    <nav className='row'>
                        <nav className='col-12 col-md-6'>
                            <span>Название модуля</span>
                            <input className='input-admin-module' type='text' onChange={(e)=>setnameCourse(e.target.value)}/>
                        </nav>
                        <nav className='col-12 col-md-6'>
                            <span>Автор модуля</span>
                            <input className='input-admin-module' type='text' onChange={(e)=>setauthorCourse(e.target.value)}/>
                        </nav>
                        <nav className='col-12 col-md-6'>
                            <span>Изображение модуля</span>
                            <input className='input-admin-module' type='file' onChange={(e)=> setFile(e.target.files[0])}/>
                        </nav>
                    </nav>
                    <button onClick={addModul} className='btn-update-curse-info'>Создать модуль</button>
                </>
            }
        </>
    );
};

export default AdminAddModules;