import React, { useContext } from 'react';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';
import { CheckAuth } from './CheckAuth';
import {  RouterProvider } from "react-router-dom";
import {router, router_auth} from '../routes/root'




const RouterApp = observer(() => {
    CheckAuth()
    const {user} = useContext(Context)

    return(
        <>
            {user.getUser ? 
                <RouterProvider router={router} />
                : 
                <RouterProvider router={router_auth} />
            }
        </>    
    )
})

export default RouterApp;