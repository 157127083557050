import {$authHost, $host} from "./index";

export const getCoursesAdmin = async() => {
    const {data} = await $host.get('api/course/')
    return data
}

export const getModuleAdmin = async(id) =>{
    const {data} = await $host.get('api/course/modules/' + id)
    return data
}

export const getLessonCategories = async(id, modules) => {
    const {data} = await $host.get('api/course/lessons/'+id+'/'+modules)
    return data
}

export const getLessonCategoriesList = async(id) =>{
    const {data} = await $host.get('api/course/lesssoncategories/'+id)
    return data
}

export const getLessonMaterial = async(id)  =>{
    const {data} = await $host.get('api/course/lessonmaterial/'+id)
    return data
}

export const addCourse = async(name,author) => {
    const {data} = await $authHost.post('api/course/', {name,author})
    return data
}

export const addModules = async(device) =>{
    const {data} = await $authHost.post('api/course/modules', device)
    return data    
}

export const addCategories = async(categories) =>{
    const {data} = await $authHost.post('api/course/lessons', categories)
    return data
}

export const addLesson = async(lesson) =>{
    const {data} = await $authHost.post('api/course/lessoncategories', lesson)
    return data
}

export const addLessonMaterial = async(material) =>{
    const {data} = await $authHost.post('api/course/lessonmaterial', material)
    return data
}


export const deleteCourse = async(id) => {
    const {data} = await $authHost.delete('api/course/' + id)
    return data
}

export const deleteModule = async(id) => {
    const {data} = await $authHost.delete('api/course/modules/' + id)
    return data
}

export const deleteLessonCategories = async(id) => {
    const {data} = await $authHost.delete('api/course/lessons/' + id)
    return data
}

export const deleteLesson = async(id) => {
    const {data} = await $authHost.delete('api/course/lessoncategories/' + id)
    return data
}

export const deleteLessonMaterial = async(id) => {
    const {data} = await $authHost.delete('api/course/lessonmaterial/' + id)
    return data
}

export const updateCourse = async(info) => {
    const {data} = await $authHost.patch('api/course', info)
    return data
}

export const updateModule = async(info) => {
    const {data} = await $authHost.patch('api/course/modules', info)
    return data
}

export const updateLessonCategories = async(info) => {
    const {data} = await $authHost.patch('api/course/lessons', info)
    return data
}

export const updateLesson = async(info) => {
    const {data} = await $authHost.patch('api/course/lessoncategories', info)
    return data
}

export const updateLessonMaterial = async(info) => {
    const {data} = await $authHost.patch('api/course/lessonmaterial', info)
    return data
}



export const getUserList = async() =>{
    
}




export const fetchModules = async (courseid) => {
    const {data} = await $host.get('api/course/modules/' + courseid)
    return data
}

export const createDevice = async (device) => {
    const {data} = await $authHost.post('api/course', device)
    return data
}

export const fetchDevices = async () => {
    const {data} = await $host.get('api/course')
    return data
}

export const fetchOneDevice = async (id) => {
    const {data} = await $host.get('api/course/' + id)
    return data
}

export const fetchCoursesList = async ( courseid, moduleid ) =>{
    const {data} = await $host.get('api/course/lessons/' + courseid + '/' + moduleid)
    return data
}

export const fetchLessonMaterial = async ( lessonid ) =>{
    const {data} = await $host.get('api/course/lessonmaterial/' + lessonid)
    return data
}

export const fetchLessonCategoriesList = async (id) => {
    const {data} = await $host.get('api/course/lesssoncategories/' + id)
    return data
}