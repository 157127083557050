import React, {useEffect, useState} from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ModuleList from '../components/ModuleList';
import { Outlet, useParams } from 'react-router-dom';


import {fetchOneDevice} from "../http/deviceApi";

const CheckModule = () => {

    const {id, name} = useParams()
    const [activeType, setActiveType] = useState([])

    useEffect(() => {
        fetchOneDevice(id).then(data => {
            setActiveType(data)
        })
    }, [])
    
    return (
        <>
            {
                !name ? 
                <>
                    <Header />
                        <nav className="container-fluid" style={{marginTop:'50px'}}>
                            <h2>{activeType.name}</h2>
                            <nav className="row">
                                <ModuleList />
                            </nav>
                        </nav>
                    <Footer />
                </> :
                <>
                    <Outlet />
                </>
            }
            
        </>
    );
};

export default CheckModule;