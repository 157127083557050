import React, {useEffect, useState} from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Document } from 'react-pdf'

import {fetchCoursesList, fetchLessonMaterial} from "../http/deviceApi";

import './WatchLessons.css'

import LogoLesson from '../assets/logo-lesson.png'
import IconHome from '../assets/svg/iconoir_home.svg'
import IconUser from '../assets/svg/user-icon-white.svg'
import LineBarLeft from '../assets/svg/line-left-bar-lessons.svg'
import documentIcon from '../assets/svg/video-document.svg'

import LessonLeftBar from '../components/LessonLeftBar';

const WatchLesson = () => {
    const navigate = useLocation()
    const {id, name, lesson} = useParams()

    const [activeType, setActiveType] = useState([])
    const [LessonMaterial, setLessonMaterial] = useState([])

    useEffect(() => {
        fetchCoursesList(id, name).then(data => {
            setActiveType(data)
        })
    }, [id, name])
    

    useEffect(() => {
        fetchLessonMaterial(lesson).then(data => {
            setLessonMaterial(data)
        })
    }, [lesson])

    let url_back = window.location.href.split('/')
    url_back = url_back[0]+'//'+url_back[2]+'/'+url_back[3]+'/'+url_back[4]+'/'+url_back[5]

    return (
        <>
                <nav className='container-fluid'>
                    <nav className="d-block d-sm-block d-md-block d-lg-none block-back-module-select">
                        <a href="#"><img src="/assets/img/svg/arrow-left.svg" /> <span>К программе модуля</span></a>
                    </nav>
                    <nav className="row">
                        <nav className="col-3">
                            <nav className="lesson-left-menu-list d-none d-sm-none d-md-none d-lg-block">
                                <nav className="row">
                                    <nav className="col-5">
                                        <img className="img-logo-lesson" src={LogoLesson} alt='LogoLessons'/>
                                    </nav>
                                    <nav className="col-1 offset-4">
                                        <NavLink to={'/'}><img className="icon-header-lesson-left-menu" src={IconHome} alt='IconHome'/></NavLink>
                                    </nav>
                                    <nav className="col-1">
                                        <NavLink to={'/settings'}><img className="icon-header-lesson-left-menu" src={IconUser} alt='IconUser'/></NavLink>
                                    </nav>
                                </nav>
                                <h4 className="title-left-bar-lesson">{navigate.state.name}</h4>
                                <img className="line-center-left-bar" src={LineBarLeft} alt="LineBar" />
                                <p className="percent-end-left-bar-lesson"></p>
                                <nav className="border-line-left-bar"></nav>
                                <nav className="lesson-block-left-bar">
                                    {
                                        activeType.map(type => 
                                            <nav key={type.id} className="course-lection-block">
                                                <h4 className="title-header-solo-line-lection-lesson">{type.name}</h4>
                                                <nav className='row'>
                                                    <LessonLeftBar id={type.id}/>
                                                </nav>
                                            </nav>    
                                        )
                                    }
                                </nav>
                            </nav>
                        </nav>
                        <nav className="col-12 col-lg-9">
                            <nav className="row">
                                <nav className="col-12 col-md-8 col-lg-6">
                                    <h3 className="title-name-lesson"><img src={documentIcon} alt='DocumentIcon'/> {navigate.state.name}</h3>
                                </nav>
                                <nav className="col-12 col-md-6 col-lg-6" style={{"textAlign": "right"}}>
                                    <NavLink to={url_back}><button className="btn-lesson-end-start">Завершить и продолжить</button></NavLink>
                                </nav>
                            </nav>
                            {
                                console.log(navigate.state.type_l)}
                            {
                                navigate.state.type_l === 1 ?
                                    <nav className="video_block_lesson">
                                        <video src={LessonMaterial ? 'http://localhost:5000/'+LessonMaterial.url_material : ''} width="100%" height="90%" controls controlsList="nodownload">
                                        </video>
                                    </nav>
                                    :
                                    <>
                                        <iframe src={LessonMaterial ? 'http://localhost:5000/'+LessonMaterial.url_material+'#toolbar=0&navpanes=0' : ''} width="100%" height="90%"></iframe>
                                    </>
                            }
                        </nav>
                    </nav>
                </nav>
        </>
    );
};

export default WatchLesson;