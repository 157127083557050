import React from 'react';

import {useEffect, useState} from 'react';

import { getAdminDashboardInfo, getAdminUser } from '../http/userApi';

import { Container } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


const AdminUserPage = () => {

    const [userData, setuserData] = useState([])
    const [editUserModal, seteditUserModal] = useState(false);
    
    const [nameUser, setnameUser] = useState('')
    const [emailUser, setemailUser] = useState('')

    const [numofMap, setnumofMap] = useState(0)

    useEffect(()=>{
        getAdminUser().then(data =>{
            setuserData(data.user)
        })
    }, [])

    function editUser(id){
        console.log(userData[id])
        setnameUser(userData[id].name_user)
        setemailUser(userData[id].email)
        seteditUserModal(true)
        setnumofMap(0)
    }

    return (
        <>
            <h3>Список пользователей</h3>
            <Container fluid>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>ФИО</th>
                            <th>Email</th>
                            <th>Роль</th>
                            <th>Дата регистрации</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userData.map(user_data  => 
                                <tr key={user_data.id}>
                                    <td>{user_data.id}</td>
                                    <td>{user_data.name_user}</td>
                                    <td>{user_data.email}</td>
                                    <td>{user_data.role === "ADMIN" ? <Button variant="success">Администратор</Button> : <Button variant="primary">Пользователь</Button>}</td>
                                    <td>{user_data.registration_data}</td>
                                    <td><Button variant="secondary" >Редактировать</Button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Container>
            <Modal
                size="lg"
                show={editUserModal}
                onHide={() => seteditUserModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Пользователь: {nameUser}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="danger">Удалить аккаунт пользователя</Button>
                    <div>
                        <Form.Label htmlFor="inputEmail">Эл. почта</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputEmail"
                            aria-describedby="passwordHelpBlock"
                            defaultValue={emailUser}  
                            onChange={(e)=>setemailUser(e.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2">
                        <Button variant="success">Сохранить</Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default AdminUserPage;