import React, { useContext } from 'react';
import {useEffect, useState} from 'react';
import user_photo from '../assets/photo-cadr-user.png'
import { changeUserPassword, getUserInfo, updateUserImage, updateUserInfo } from '../http/userApi';
import { observer } from 'mobx-react-lite';
import { jwtDecode } from "jwt-decode";

import { Context } from '../index';

const SettingsChangePassword = observer(() => {
    
    const {user} = useContext(Context)

    const inputFile = React.createRef();

    const [emailFirst, setemailFirst] = useState('')

    const [activeType, setActiveType] = useState([])

    const [changePasswordSelected, setChangePasswordSelected] = useState(false)
    
    const localStorageUser = jwtDecode(localStorage.getItem('token'))

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    useEffect(() => {
        getUserInfo(localStorageUser.email).then(data => {
            setActiveType(data)
            setemailFirst(data.email)
        })
    }, [])

    const update_course = (files) => {
        const formData = new FormData()
        formData.append('email', user.getUser.email)
        formData.append('img', files)
        updateUserImage(formData).then((e) => setActiveType(prevState => ({...prevState,user_image: e})))
        
    }

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const saveChanges = async () =>{
        if(changePasswordSelected){
            changeUserPassword(localStorageUser.email, oldPassword, newPassword).then((e) =>{
                console.log(e)
            })
        }else{
            try {
                const formData = new FormData()
                formData.append('name', activeType.name)
                formData.append('email', activeType.email)
                formData.append('old_email', emailFirst)
                if(activeType.email === emailFirst){
                    formData.append('status', '0')
                }else{
                    formData.append('status', '1')
                }
                
                updateUserInfo(formData).then((e) => {
                    if(e.msg){
                        alert(e.msg)
                    }else{
                        const token = jwtDecode(e.token)
                        user.setUser(token)
                        localStorage.setItem('token', e.token)
                        user.setIsAuth(true)
                        alert('Информация успешно обновлена!')
                    }
                })

                
            } catch (e) {
                if (e.response.data[0]) {
                    alert(e.response.data[0].msg)
                }else{
                    alert(e.response.data.msg)
                }
            }
        }
    }
    

    return (
        <>
            {!user ? <></> : <></>}
            {!changePasswordSelected ? <>
                <h4 className="title-edit-profile-setting-page">Редактирование профиля</h4>
                <nav className="row">
                    <nav className="col-12 col-md-6">
                        <nav className="block-edit-profile-1">
                            <p>ФИО</p>
                            <input type="text" defaultValue={activeType ? activeType.name : ''}  onChange={(e)=>setActiveType(prevState => ({...prevState,name: e.target.value}))}/>
                            <p>Email</p>
                            <input type="text" defaultValue={activeType ? activeType.email : ''} onChange={(e)=>setActiveType(prevState => ({...prevState,email: e.target.value}))}/>
                        </nav>
                    </nav>
                    <nav className="col-12 col-md-6">
                        <nav className="block-edit-profile-2">
                            <nav className="row">
                                <nav className="col-4">
                                    <img className="images-profile-user-settings" src={!activeType.user_image ? user_photo : process.env.REACT_APP_API_URL+activeType.user_image} alt='user avatar'/>
                                </nav>
                                <nav className="col-8">
                                    <h4 className="title-image-load-profile-settings">Изображение профиля</h4>
                                    <ul className="ul-list-photo-need">
                                        <li>Максимальный размер изображения - 5 Мб</li>
                                        <li>Большие изображения будут автоматически уменьшены</li>
                                    </ul>
                                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={(e)=> update_course(e.target.files[0])}/>
                                    <button className="btn-send-image-profile-settings" onClick={onButtonClick}>Загрузить изображение</button>
                                </nav>
                            </nav>
                        </nav>
                    </nav>
                    <nav className="col-12 col-md-6">
                        <nav className="block-edit-profile-3">
                            <h4>Уведомления</h4>
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                            Да, PLP Technology - может присылать мне по электронной почте информацию об акциях и новостях.
                            </label>
                        </nav>
                    </nav>
                </nav>
            </> : <>
                <h4 className="title-edit-profile-setting-page">Смена пароля</h4>
                <nav className="row">
                    <nav className="col-12 col-md-6">
                        <nav className="block-edit-profile-1">
                            <p>Введите актуальный пароль</p>
                            <input type="text" defaultValue={oldPassword}  onChange={(e)=>setOldPassword(e.target.value)}/>
                            <p>Укажите новый пароль</p>
                            <input type="text" defaultValue={newPassword}  onChange={(e)=>setNewPassword(e.target.value)} />
                        </nav>
                    </nav>
                </nav>
            </>}
            
            <nav className="btn-save-and-reset-password">
                <button onClick={()=> setChangePasswordSelected(!changePasswordSelected)}>{changePasswordSelected ? <>Отмена</> : <>Изменить пароль</>}</button>
                <button onClick={saveChanges}>Сохранить</button>
            </nav>
        </>
    );
});

export default SettingsChangePassword;