import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";


export const registration = async (email, password) => {
    const {data} = await $host.post('api/user/registration', {email, password})
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const login = async (email, password) => {
    const {data} = await $host.post('api/user/login', {email, password})
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}
export const check = async () => {
    const {data} = await $authHost.get('api/user/auth')
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const getUserInfo = async (email) => {
    const {data} = await $authHost.get('api/user/getinfo/' + email)
    return data
}

export const resetPassword = async(email) =>{
    const {data} = await $host.post('api/user/resetpassword', {email})
    return data
}

export const checkResetPassword = async(email, code) =>{
    const {data} = await $host.post('api/user/checkresetpassword', {email, code})
    return data
}

export const updateUserImage = async(info) => {
    const {data} = await $authHost.patch('api/user/updateuserimage', info)
    return data
}

export const updateUserInfo = async(info) => {
    const {data} = await $authHost.patch('api/user/updateuserinfo', info)
    return data
}

export const changeUserPassword = async(email, oldPassword, newPassword) => {
    const {data} = await $authHost.patch('api/user/changeuserpassword', {email, oldPassword, newPassword})
    return data
}

export const getAdminUser = async () => {
    const {data} = await $authHost.get('api/user/getadminuser/')
    return data
}

export const getAdminDashboardInfo = async() => {
    const {data} = await $authHost.get('api/user/getadmindashboard/')
    return data
}