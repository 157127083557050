import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';

import user_img from '../assets/student.png'
import material_img from '../assets/teacher.png'
import { getAdminDashboardInfo } from '../http/userApi';

export default function AdminDashboard() {
    const [dashboard, setDashboard] = useState([])
    useEffect(()=>{
        getAdminDashboardInfo().then(data =>{
            setDashboard(data)
        })
    }, [])
  return (
    <>
      <h3>Панель статистики</h3>
      <Container fluid>
        <Row>
            <Col sm={6} className='dashboard-left-stat-col'>
                <Row>
                    <Col sm={6}>
                        <Card className='dashboard-card-index'>
                            <Card.Body >
                                <div className="d-flex gap-2 align-items-end"> 
                                    <div className="flex-grow-1">
                                        <h2>{dashboard.user_count}</h2>
                                        <p className="mb-0"> Всего студентов</p>
                                    </div>
                                    <div className="flex-shrink-0"><img src={user_img} alt="user-img" /></div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card className='dashboard-card-index'>
                            <Card.Body>
                                <div className="d-flex gap-2 align-items-end"> 
                                    <div className="flex-grow-1">
                                        <h2>{dashboard.course_count}</h2>
                                        <p className="mb-0"> Всего материалов</p>
                                    </div>
                                    <div className="flex-shrink-0"><img src={material_img} alt="user-img" /></div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card className='dashboard-card-index'>
                            <Card.Body>
                                <div className="d-flex gap-2 align-items-end"> 
                                    <div className="flex-grow-1">
                                        <h2>{dashboard.course_active_count}</h2>
                                        <p className="mb-0">Активных материалов</p>
                                    </div>
                                    <div className="flex-shrink-0"><img src={material_img} alt="user-img" /></div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card className='dashboard-card-index'>
                            <Card.Body>
                                <div className="d-flex gap-2 align-items-end"> 
                                    <div className="flex-grow-1">
                                        <h2>{dashboard.user_moder_count}</h2>
                                        <p className="mb-0">Кураторов</p>
                                    </div>
                                    <div className="flex-shrink-0"><img src={material_img} alt="user-img" /></div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col sm={6}>
                
            </Col>
        </Row>
      </Container>
    </>
  )
}
