import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import CategoriesListLesson from '../components/CategoriesListLesson'

import {fetchCoursesList} from "../http/deviceApi";
import Header from '../components/Header';
import Footer from '../components/Footer';

const WatchCourse = () => {

    const {id, name} = useParams()

    const [activeType, setActiveType] = useState([])

    useEffect(() => {
        fetchCoursesList(id, name).then(data => {
            setActiveType(data)
        })
    }, [id, name])

    return (
        <> 
            <Header />
            <nav className="container-fluid">
                <nav className="name-line-header">
                    <nav className="row">
                        <nav className="col-6 col-lg-4">
                            <p className="name-course-header-line">Программа курса</p>
                        </nav>
                        <nav className="col-6 col-lg-3 offset-lg-2">
                            <p className="percent-over-header-line">100% завершенно</p>
                        </nav>
                        <nav className="col-10 offset-1 offset-lg-0 col-lg-3" style={{textAlign: 'right'}}>
                            <a href="#"><button className="btn-next-course-header-line">Перейти к следующему модулю</button></a>
                        </nav>
                    </nav>
                </nav>
            </nav>
            <nav className='container-fluid'>
            {
                activeType.map(type => 
                    <nav key={type.id} className="course-lection-block">
                        <h4 className="title-header-solo-line-lection">{type.name}</h4>
                        <nav className='row'>
                            <CategoriesListLesson id={type.id} />
                        </nav>
                    </nav>    
                )
            }
            </nav>
            <Footer />
        </>
    );
};

export default WatchCourse;