import React, {useEffect, useState} from 'react';
import { NavLink, useParams } from 'react-router-dom';

import succ100 from '../assets/svg/succ-100.svg'

import {fetchModules} from "../http/deviceApi";

const ModuleList = () => {

    const [activeType, setActiveType] = useState([])

    const {id} = useParams()

    useEffect(() => {
        fetchModules(id).then(data => {
            setActiveType(data)
        })
    }, [])

    return (
       <>
       {
            activeType.map(type => 
                <nav key={type.id} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                    <nav className="material-block-solo-out-big">
                        <img src={process.env.REACT_APP_API_URL + type.img} className="image-material-block-solo" alt='module header img' />
                        <nav className="material-block-solo-big-padding">
                            <p className="material-block-solo-category-name">Название</p>
                            <p className="material-block-solo-course-name">{type.namemodule}</p>
                                <NavLink to={'/module/'+id+'/'+type.id}><p className="material-block-solo-material-count">Перейти к изучению</p></NavLink>
                            <nav className="row">
                                <nav className="col-6">
                                    <p className="material-block-solo-author-name">{type.author}</p>
                                </nav>
                                <nav className="col-6">
                                    <p className="material-block-solo-percent-end">Завершено 100% <img src={succ100} alt='process icon'/></p>
                                </nav>
                            </nav>
                        </nav>
                    </nav>
                </nav>
            )
        }
       </>
    );
};

export default ModuleList;