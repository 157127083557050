import React, { useContext, useState } from 'react';
import './LoginPage.css'

import logotype from '../assets/logo-vap-menu-header.png'
import { NavLink, useLocation } from 'react-router-dom';
import { LOGIN_ROUTE, PUBLICK_OFFERT, REGISTRATION_ROUTE } from '../routes/const';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';
import { login, registration } from '../http/userApi';

const LoginPage = observer(() => {
    const {user} = useContext(Context)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [textError, setTextError] = useState('')
    const [visibleError, setvisibleError] = useState('hidden')

    const [isSubscribed, setIsSubscribed] = useState(false);
    
    const handleChange = () => {
        setIsSubscribed(current => !current);
      };

    const click = async () => {
        try {
            let data;
            if (isLogin) {
                data = await login(email, password);
            } else {
                if(!isSubscribed){
                    return alert('Подтвердите согласение с политикой конфиденциальности и согласием на обработку персональных данных')
                }
                data = await registration(email, password);
            }
            user.setUser(data)
            user.setIsAuth(true)
            window.location.replace('/')
        } catch (e) {
            if (e.response.data[0]) {
                setTextError(e.response.data[0].msg)
            }else{
                setTextError(e.response.data.msg)
            }
            setvisibleError('visible')
        }
    }

    const location = useLocation()
    const isLogin = location.pathname === LOGIN_ROUTE

    return (
        <nav className="container">
            <nav className="row">
                <nav className="col-12 offset-0 col-sm-12 col-md-10 offset-md-1 col-lg-9 offset-lg-1 col-xl-8 offset-xl-2 col-xxl-8 offset-xxl-2">
                    <img className="logo-vap" src={logotype} alt="logo"/>
                    <nav className="container-auth">
                        <nav className='row'>
                            <nav className='col-12 col-md-8 offset-md-2'>
                                <h3>{isLogin ? 'Вход в личный кабинет' : 'Регистрация'}</h3>
                                <nav className='block-error-auth' style={{'visibility':visibleError}}>
                                    {textError}
                                </nav>
                                <input type="text" placeholder="Эл. почта" value={email} onChange={e => setEmail(e.target.value)}/>
                                <input type="password" placeholder="Пароль" value={password} onChange={e => setPassword(e.target.value)}/>
                                {isLogin ? <p><NavLink to="/reset-password">Забыли пароль?</NavLink></p> : ''}
                                {isLogin ? 
                                    <p className="text-auth-under">Авторизуясь в личном кабинете вы принимаете условия <NavLink to={PUBLICK_OFFERT}>политики конфиденциальности</NavLink> и даете согласие на обработку ваших персональных данных.</p> 
                                    :
                                    <p className="text-auth-under"><input type="checkbox" value={isSubscribed} onChange={handleChange} style={{"margin":"0", "padding":"0", "width": "13px", "height":"13px", "vertical-align":"bottom", "position":"relative", "top":"-1px","overflow":"hidden"}} /> Я согласен с <NavLink to={PUBLICK_OFFERT}>политикой конфиденциальности</NavLink> и даю согласие на обработку персональных данных. </p>
                                }
                                {isLogin ? <button onClick={click} className="btn-auth-enter" type="button">Войти</button> : <button onClick={click} className="btn-auth-enter" type="submit">Зарегистрироваться</button>}
                                {isLogin ? <NavLink to={REGISTRATION_ROUTE}><button className="btn-auth-registration">Регистрация</button></NavLink> : <NavLink to={LOGIN_ROUTE}><button className="btn-auth-registration">Авторизоваться</button></NavLink>}
                            </nav>
                        </nav>
                    </nav>
                </nav>
            </nav>
        </nav>
    );
});

export default LoginPage;