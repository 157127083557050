import React, { useContext, useState } from 'react';
import './ResetPassword.css';

import { NavLink, useLocation } from 'react-router-dom';

import { LOGIN_ROUTE } from '../routes/const';


import logotype from '../assets/logo-vap-menu-header.png'
import { checkResetPassword, resetPassword } from '../http/userApi';

const ResetPassword = () => {
    let textInput2 = React.createRef();
    let textInput3 = React.createRef();
    let textInput4 = React.createRef();
    
    
    const [email, setEmail] = useState('')

    const [step, setStep] = useState(false)
    const [end_p, setEnd_p] = useState(false)
    
    const [key1,setKey1] = useState(0)
    const [key2,setKey2] = useState(0)
    const [key3,setKey3] = useState(0)
    const [key4,setKey4] = useState(0)

    const [textError, setTextError] = useState('')
    const [visibleError, setvisibleError] = useState('hidden')

    const changeKey = (key, number) =>{
        if(key === 1){
            var c = number
            if (c >= '0' && c <= '9') {
                setKey1(number)
                textInput2.current.focus()
            }
        }
        if(key === 2){
            var c = number
            if (c >= '0' && c <= '9') {
                setKey2(number)
                textInput3.current.focus()
            }
        }
        if(key === 3){
            var c = number
            if (c >= '0' && c <= '9') {
                setKey3(number)
                textInput4.current.focus()
            }
        }
        if(key === 4){
            var c = number
            if (c >= '0' && c <= '9') {
                setKey4(number)
            }
        }
    }

    const click = async () => {
        try {
            setvisibleError('hidden')
            if (!step) {
                await resetPassword(email);
                setStep(true)
            } else {
                var code = key1+key2+key3+key4;
                await checkResetPassword(email,code)
                setEnd_p(true)
            }

        } catch (e) {
            if (e.response.data[0]) {
                setTextError(e.response.data[0].msg)
            }else{
                setTextError(e.response.data.msg)
            }
            setvisibleError('visible')
        }
    }

    return (
        <nav className='container'>
            <nav className='row'>
                <nav className='col-12 offset-0 col-sm-12 col-md-10 offset-md-1 col-lg-9 offset-lg-1 col-xl-8 offset-xl-2 col-xxl-8 offset-xxl-2'>
                    <img className="logo-vap" src={logotype} alt="logo"/>
                    <nav className="container-reset-password">
                        <nav className='row'>
                            <nav className='col-12 col-md-8 offset-md-2'>
                                {
                                    !end_p?
                                    <>
                                        <h3>Восстановление пароля</h3>
                                        <nav className='block-error-auth' style={{'visibility':visibleError}}>
                                            {textError}
                                        </nav>
                                        {
                                            !step?
                                            <>
                                                <input className='email-reset-password' type="text" placeholder="Эл. почта" value={email} onChange={e => setEmail(e.target.value)}/>
                                                <button className="btn-auth-enter" type="submit" onClick={click}>Восстановить</button>
                                            </>
                                            :
                                            <>
                                                <p>Введите код отправленный на почту</p>
                                                <nav className='block-code-reset-center'>
                                                    <input className="input-reset-password" type="tel" maxLength="2" placeholder="·" value={key1} onChange={e => changeKey(1,e.nativeEvent.data)}/>
                                                    <input className="input-reset-password" type="tel" ref={textInput2} maxLength="2" placeholder="·" value={key2} onChange={e => changeKey(2,e.nativeEvent.data)} />
                                                    <input className="input-reset-password" type="tel" ref={textInput3} maxLength="2" placeholder="·" value={key3} onChange={e => changeKey(3,e.nativeEvent.data)} />
                                                    <input className="input-reset-password" type="tel" ref={textInput4} maxLength="2" placeholder="·" value={key4} onChange={e => changeKey(4,e.nativeEvent.data)} />
                                                </nav>
                                                <button className="btn-auth-enter" type="submit" onClick={click}>Сбросить пароль</button>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <h3>Письмо с новым паролем отправлено вам на почту!</h3>
                                    </>
                                }
                                <NavLink to={LOGIN_ROUTE}><button className="btn-auth-registration">Авторизоваться</button></NavLink>
                            </nav>
                        </nav>
                    </nav>
                </nav>
            </nav>
        </nav>
    );
};

export default ResetPassword;