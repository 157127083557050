import React, {useEffect, useState} from 'react';

import {fetchLessonCategoriesList} from "../http/deviceApi";
import typelesson from '../assets/svg/callback.svg'
import overprecent from '../assets/svg/over-75.svg'
import { NavLink } from 'react-router-dom';

const CategoriesListLesson = (id) => {
    const [activeLesson, setActiveLesson] = useState([])

    useEffect(() => {
        fetchLessonCategoriesList(id.id).then(data => {
            setActiveLesson(data)
        })
    }, [])
    
    return (
        <>
            {
                activeLesson.map(type => 
                    <nav className="col-12" key={type.id}>
                        <nav className="lection-solo-line">
                            <nav className="row">
                                <nav className="col-2 col-md-1 col-lg-1 block-absolute-right-line">
                                    <img className="image-process-percent" src={overprecent} alt='precentimage' />
                                    <nav className="block-right-line"></nav>
                                </nav>
                                <nav className="col-2 col-md-1 col-lg-1">
                                    <img className="image-process-percent" src={typelesson} alt='typeimage' />
                                </nav>
                                <nav className="col-8 col-lg-8">
                                    <p className="name-lection-solo-line">{type.name}</p>
                                </nav>
                                <nav className="d-none d-sm-none d-md-none d-lg-block col-lg-2" style={{textAlign: 'right'}}>
                                    <NavLink to={'./' + type.id} state={{name: type.name, type_l: type.typel}}><button className="btn-start-lection-solo">Начать</button></NavLink>
                                </nav>
                            </nav>
                        </nav>
                    </nav>
                )
            }
        </>
    );
};

export default CategoriesListLesson;