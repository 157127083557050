import React from 'react';
import { Container } from 'react-bootstrap';
import logotype from '../assets/logo-vap-menu-header.png'
import { NavLink } from 'react-router-dom';

const PublickOffert = () => {
return (
    <>
        <Container style={{"background":"white"}}>
            <nav className="container-fluid" style={{"border-bottom":"2px solid black","margin-bottom":"15px"}}>
                <nav className="menu-header">
                    <nav className="row">
                        <nav className="col-7 col-lg-2">
                            <NavLink to={'/'}> <img className="logo-menu-header" src={logotype} alt="logo"/> </NavLink>
                        </nav>
                        <nav className="col-7 col-lg-2" style={{"padding":"20px"}}>
                            <NavLink to={'/'} style={{"color":"black"}}> На главную</NavLink>
                        </nav>
                    </nav>
                </nav>
            </nav>
            <p>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ИП НОЙМАНН-ЗАНДЕР НАРГИЗА ХАЛИЛОВНА &nbsp;</p>
            <p>&laquo;Академия Нойманн&raquo;</p>
            <p>&nbsp;</p>
            <ol>
            <li>Общие понятия</li>
            </ol>
            <p>1.1. Политика Индивидуального предпринимателя Нойманн-Зандер Наргиза Халиловна (далее &ndash; Оператор)&nbsp; в отношении обработки персональных данных (далее &ndash; Политика) в рамках цифровой платформы &laquo;Академия Нойманн&raquo; (далее &ndash; Платформа) &nbsp;разработана в соответствии с Федеральным законом №152-ФЗ &laquo;О персональных данных&raquo; от 27.07.2006 г. и иными актами РФ.</p>
            <p>1.2.&nbsp; Настоящая Политика определяет порядок обработки персональных данных пользователей Платформы, условия и принципы обработки персональных данных, права Пользователей и обязанности Оператора , сведения о реализуемых мерах по защите обрабатываемых персональных данных.</p>
            <p>1.3. Настоящая Политика действует в отношении всех персональных данных, которые Оператор получает от пользователей Платформы &nbsp;(далее &ndash; Пользователи, Пользователь).</p>
            <p>1.4. В случае несогласия с условиями Политики Пользователь должен немедленно прекратить любое использование Платформой.</p>
            <ol start="2">
            <li><strong> Термины и определения</strong></li>
            </ol>
            <p>Персональные данные (далее - ПДн) - любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу (субъекту ПДн);</p>
            <p>Блокирование ПДн - временное прекращение обработки ПДн (за исключением случаев, если обработка необходима для уточнения ПДн);</p>
            <p>Обезличивание ПДн - действия, в результате которых невозможно определить без использования дополнительной информации принадлежность ПДн конкретному субъекту ПДн;</p>
            <p>Обработка ПДн - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение ПДн;</p>
            <p>Оператор &ndash; индивидуальный предприниматель Нойманн-Зандер Наргиза Халиловна. ИП Нойманн-Зандер Наргиза Халиловна самостоятельно организует и осуществляет обработку ПДн, а также определяет цели обработки ПДн, состав ПДн, подлежащих обработке, действия (операции), совершаемые с ПДн.</p>
            <p>Личный кабинет - совокупность защищенных страниц Сайта Платформы, созданных в результате регистрации Пользователя и доступных при вводе его аутентификационных данных (адреса электронной почты и пароля) в предусмотренные для этого поля на Сайте Платформы;</p>
            <p>Предоставление ПДн - действия, направленные на раскрытие ПДн определенному лицу или определенному кругу лиц;</p>
            <p>Распространение ПДн - действия, направленные на раскрытие ПДн неопределенному кругу лиц (передача ПДн) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование ПДн в средствах массовой информации, размещение в информационно&ndash;телекоммуникационных сетях или предоставление доступа к персональным данным каким&ndash;либо иным способом;</p>
            <p>Пользователь &ndash; физическое лицо, зарегистрировавшиеся на платформе. Субъект ПДн, а также лицо, имеющее доступ к Платформе, посредством сети Интернет и использующее информацию и материалы Платформы без прохождения регистрации.</p>
            <p>Уничтожение ПДн - действия, в результате которых невозможно восстановить содержание ПДн, и (или) в результате которых уничтожаются материальные носители ПДн;</p>
            <p>Файлы cookie (куки) - данные, которые автоматически передаются Оператору в процессе использования Платформы с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, географическое местоположение, информация о браузере и виде операционной системы устройства Пользователя, технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Платформе. Принимая условия Политики Пользователь соглашается на использование файлов cookie;</p>
            <p>&laquo;Конфиденциальность персональных данных&raquo; - обязательное для соблюдения Оператором требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
            <ol start="3">
            <li>Цели использования персональных данных</li>
            </ol>
            <p>3.1. Оператор обрабатывает ПДн Пользователя исключительно в следующих целях:</p>
            <p>3.2.1. Регистрация и идентификация Пользователя на Платформе, предоставление Пользователю возможности полноценного использования услугами Платформы, включая управление предусмотренной в личном кабинете информацией, которая может быть изменена пользователем самостоятельно.</p>
            <p>3.2.2. Отображение профиля Пользователя для иных Пользователей Платформы;</p>
            <p>3.2.3. Установление и поддержание коммуникации между Пользователем и Оператором, консультирование по вопросам оказания услуг Оператора и пользования Платформой, обеспечение коммуникации между Пользователями Платформы;</p>
            <p>3.2.4. Обеспечение оказания Оператором услуг Пользователю во исполнение ранее заключенных договоров между Пользователем и Оператором и исполнение Оператором обязательств перед Пользователем по Договорам (в частности договорам оказания услуг по обучению). Исполнение обязательств, в частности, включает в себя информирование о дате и времени проведения занятий (в том числе путем обзвона и направления смс сообщений, направления сообщений в мессенджерах, пуш-уведомлений), обеспечение доступа к обучающим материалам.</p>
            <p>3.2.5. Направление Оператором Пользователю сообщений рекламного характера, информационных рассылок о продуктах, услугах Оператора и его партнеров, о специальных предложениях, рекламных акциях, розыгрышах, конкурсах, опросах на адрес электронной почты Пользователя, по номеру телефона Пользователя (имени Пользователя), в мессенджерах Пользователя. Регистрация на Платформе одновременно является согласием пользователя на получение информации рекламного характера, упомянутой в настоящем пункте.</p>
            <p>3.2.6. Улучшение качества обслуживания Пользователя и модернизация Платформы путем обработки запросов и заявок от Пользователя;</p>
            <p>3.2.7. Статистические и иные исследования на основе обезличенной информации, предоставленной Пользователем;</p>
            <p>3.3 Конкретный объем ПДн, обрабатываемых в указанных выше целях, определен в разделе 5 Политики.</p>
            <p>4 Условия и порядок предоставления согласия на обработку</p>
            <p>персональных данных Оператору</p>
            <p>&nbsp;</p>
            <p>Оператор не проверяет предоставляемые Пользователем ПДн. В связи с этим Оператор исходит из того, что при предоставлении ПДн на Платформу Пользователь:</p>
            <p>4.1.1. Является дееспособным лицом. В случае недееспособности лица, использующего Сайты, согласие на обработку ПДн предоставляется законным представителем Пользователя, который ознакомился и принял условия обработки ПДн, указанные в настоящей Политике.</p>
            <p>4.1.2. Указывает достоверную информацию о себе &nbsp;в объемах, необходимых для использования Платформы.</p>
            <p>4.1.2.1. Пользователь самостоятельно поддерживает предоставленные ПДн в актуальном состоянии. При этом в случае самостоятельной перемены ПДн Пользователем, Оператор оставляет за собой право на получение документов и (или) иных доказательств, подтверждающих перемену таких ПДн.</p>
            <p>4.1.2.2. Последствия предоставления Пользователем недостоверной или недостаточной информации определены в Пользовательском соглашении.</p>
            <p>4.1.3. Осознает, что информация на Сайтах, размещаемая Пользователем о себе, может становиться доступной для других Пользователей Сайтов, может быть скопирована и распространена такими Пользователями в случаях, предусмотренных Политикой.</p>
            <p>4.2.&nbsp; Пользователь принимает условия Политики и дает информированное и осознанное согласие на обработку своих ПДн на условиях, предусмотренных Политикой и Законом.</p>
            <p>4.2.1. При регистрации и идентификации на Платформе&nbsp; - для ПДн, которые Пользователь предоставляет Оператору: путем заполнения формы для регистрации, расположенной в сети Интернет по адресу АДРЕС РЕГИСТРАЦИИ НА ПЛАТФОРМЕ. Пользователь считается предоставившим согласие на обработку своих ПДн в момент нажатия кнопки &laquo;Зарегистрироваться&raquo;.</p>
            <p>4.2.2. При внесении или изменении ПДн в разделе &laquo;Персональная информация&raquo; Личного кабинета - при редактировании или дополнении информации в разделе &laquo;Персональная информация&raquo;.</p>
            <p>По желанию Пользователя в Личном кабинете им могут быть указаны следующие сведения:</p>
            <p>(ЗДЕСЬ НАДО УКАЗАТЬ СПИСКОМ, КАКИЕ СВЕДЕНИЯ О СЕБЕ МОЖЕТ РЕДАКТИРОВАТЬ И ВВОДИТЬ ПОЛЬЗОВАТЕЛЬ, НАПРИМЕР: дата рождения, пол, фото, ссылки на соцсети)</p>
            <p>Пользователь считается предоставившим согласие на обработку своих вновь внесенных или измененных ПДн в момент завершения их редактирования.</p>
            <p>4.2.3. При заполнении формы заявки на прохождения курса заявки на использование других сервисов Платформы: для ПДн, которые Пользователь предоставляет Оператору при заполнении соответствующей формы заявки. Пользователь считается предоставившим согласие на обработку своих ПДн, внесенных в поля формы обратной связи, в момент нажатия кнопки, подтверждающей отправку заявки (кнопки могут называться &laquo;Отправить&raquo;, &laquo;Оставить заявку&raquo;, &laquo;Записаться&raquo;, &laquo;Получить консультацию&raquo; и иным аналогичным образом.</p>
            <p>4.2.4. При оформлении подписки на получение информационных и новостных материалов от Оператора - путем заполнения формы для оформления подписки на рассылку, расположенной в сети Интернет по адресу АДРЕС СОГЛАСИЯ НА РАССЫЛКУ. Пользователь считается предоставившим согласие на обработку своих ПДн при проставлении галочки в поле &laquo;Я согласен с условиями обработки ПДн&raquo; или галочки в поле аналогичного характера.</p>
            <p>4.2.5. При любом использовании Платформы - для ПДн, которые автоматически передаются Оператору в процессе использования Платформы с помощью установленного на устройстве Пользователя программного обеспечения. Пользователь считается предоставившим согласие на обработку своих ПДн в момент начала использования Платформой или Сайтов составляющих Платформу.</p>
            <p>4.3. Совершая действия, указанные в п. 4.2 настоящей Политики, Пользователь дает Оператору согласие на обработку соответствующих ПДн, перечень которых указан в разделе 5 настоящей Политики, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление третьим лицам, доступ), обезличивание, блокирование, удаление, уничтожение ПДн с использованием и без использования средств автоматизации в соответствии с целями, указанными в разделе 3 настоящей Политики.</p>
            <p>4.4. Предоставленное Пользователем в соответствии с настоящей Политикой согласие на обработку ПДн действует со дня предоставления такого согласия и в течение срока, необходимого для достижения целей обработки ПДн или до момента отзыва Пользователем указанного согласия, если иное не предусмотрено действующим законодательством РФ.</p>
            <ol start="5">
            <li>Персональные данные, обрабатываемые Оператором</li>
            </ol>
            <p>5.1. Обработка ПДн Пользователей осуществляется на следующих условиях:</p>
            <table>
            <tbody>
            <tr>
            <td width="311">
            <p>Цель использования ПДн</p>
            </td>
            <td width="312">
            <p>ПДн, обрабатываемые оператором</p>
            </td>
            </tr>
            <tr>
            <td width="311">
            <p>Регистрация Пользователя на Платформе (п. 3.2.1.)</p>
            </td>
            <td width="312">
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Фамилия, имя;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; адрес электронной почты;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; номер телефона;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; дата рождения;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; пол</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; адрес аккаунта в социальных сетях</p>
            </td>
            </tr>
            <tr>
            <td width="311">
            <p>Идентификация Пользователя на Платформе (п. 3.2.1.)</p>
            </td>
            <td width="312">
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; адрес электронной почты</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; адрес аккаунта в социальных сетях</p>
            </td>
            </tr>
            <tr>
            <td width="311">
            <p>Отображение профиля для иных Пользователей Сайта (п. 3.2.2)</p>
            </td>
            <td width="312">
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; фамилия и имя;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; изображение (если указано)</p>
            </td>
            </tr>
            <tr>
            <td width="311">
            <p>Установление и поддержание коммуникации между Пользователем и Оператором, между Пользователями Платформы (п. 3.2.3)</p>
            </td>
            <td width="312">
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; фамилия и имя;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; номер телефона;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; адрес электронной почты;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; данные аккаунтов в социальных сетях и</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; мессенджерах</p>
            </td>
            </tr>
            <tr>
            <td width="311">
            <p>Оказание услуг по Договорам (п. 3.2.4)</p>
            </td>
            <td width="312">
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; фамилия и имя;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; номер телефона</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; адрес электронной почты</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;паспортные данные (серия, номер, код подразделения, дата выдачи, кем выдан, дата и место рождения);</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; адрес постоянной регистрации;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; сведения об уровне образования и об учебных заведениях;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; СНИЛС (если необходимо для исполнения договора)</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ИНН (если необходимо для исполнения п. договора)</p>
            </td>
            </tr>
            <tr>
            <td width="311">
            <p>Направление Оператором Пользователю сообщений рекламного характера, информационных рассылок о продуктах, услугах Оператора и его партнеров (п. 3.2.5.)</p>
            </td>
            <td width="312">
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; фамилия и имя;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; номер телефона;</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; адрес электронной почты</p>
            </td>
            </tr>
            <tr>
            <td width="311">
            <p>Улучшение качества обслуживания Пользователя и модернизация Платформы путем обработки запросов и заявок от Пользователя (п. 3.2.6.)</p>
            </td>
            <td width="312">
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; файлы cookie</p>
            </td>
            </tr>
            <tr>
            <td width="311">
            <p>Статистические и иные исследования на основе обезличенной информации, предоставленной Пользователем; (п. 3.2.7.)</p>
            <p>&nbsp;</p>
            </td>
            <td width="312">
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; файлы cookie</p>
            <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; сведения об уровне образования и об учебных заведениях</p>
            </td>
            </tr>
            </tbody>
            </table>
            <p>&nbsp;</p>
            <ol start="6">
            <li><strong> Обработка персональных данных</strong></li>
            </ol>
            <p>6.1.&nbsp; Оператор производит обработку ПДн Пользователей (запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение) с использованием баз данных на территории РФ.</p>
            <p>6.2. Обработка ПДн Пользователей производится Оператором как с использованием автоматизированных средств, так и без них.</p>
            <p>6.3. Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять ПДн без согласия субъекта ПДн, если иное не предусмотрено федеральным законом.</p>
            <p>6.4. Обработка ПДн Пользователя включает совершение Оператором следующих действий: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление третьим лицам, доступ), обезличивание, блокирование, удаление, уничтожение.</p>
            <p>6.5. Хранение ПДн Пользователей осуществляется на электронных носителях. При обработке ПДн с целью исполнения обязательств по соглашениям с Пользователем Оператор может извлекать ПДн и хранить их на материальных носителях. Хранение таких ПДн осуществляется в течение срока, установленного законодательством Российской Федерации об образовании и архивном деле.</p>
            <p>6.6. Хранение ПДн осуществляется (в зависимости от того, какое событие наступит раньше):</p>
            <ul>
            <li>До момента их удаления Пользователем в разделе &laquo;Персональная информация&raquo; в личном кабинете на Сайте:</li>
            <li>До момента их уничтожения Оператором &ndash; в случае поступления от Пользователя отзыва согласия на обработку ПДн или требования об уничтожении ПДн;</li>
            <li>До момента истечения срока действия согласия (п. 4.4 Политики).</li>
            </ul>
            <p>6.7. Оператор вправе осуществлять передачу ПДн в соответствии с требованиями законодательства РФ либо согласия субъекта обработки ПДн третьим лицам, а именно:</p>
            <p>6.7.1. Партнерам, таким как владельцы сайтов и приложений, рекламным сетям и другим партнерам, предоставляющие Оператору услуги, связанные с размещением и отображением рекламы на Платформе, в программах, продуктах или сервисах, которые принадлежат таким Партгнерам или контролируются ими;</p>
            <p>6.7.2. Партнерам, привлекаемым Оператором для оказания услуг Пользователям на Платформе, в томи числе Партнерам, занимающихся техническим обеспечением функционирования платформы;</p>
            <p>6.7.3. Партнерам для предоставления Пользователям специализированных предложений о заключении гражданско-правовых договоров;</p>
            <p>6.7.4. Компаниям, оказывающим Оператору рекламные и иные информационные услуги.</p>
            <p>6.8. Передача персональных данных третьим лицам, указанным в п. 6.7 Политики осуществляется при соблюдении следующих условий:</p>
            <ul>
            <li>Третье лицо осуществляет обработку ПДн с использованием баз данных на территории Российской Федерации;</li>
            <li>Третье лицо обеспечивает конфиденциальность ПДн при их обработке и использовании, обязуется не раскрывать иным лицам, а также не распространять ПДн Пользователей без их согласия;</li>
            <li>Третье лицо гарантирует соблюдение следующих мер по обеспечению безопасности ПДн при их обработке: использование средств защиты информации, обнаружение и фиксация фактов несанкционированного доступа к персональным данным и принятие мер по восстановлению ПДн, ограничение доступа к персональным данным, регистрация и учет действий с персональными данными, контроль и оценка эффективности применяемых мер по обеспечению безопасности ПДн;</li>
            <li>Перечень разрешенных способов обработки ПДн: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение;</li>
            <li>Третьему лицу запрещено осуществлять передачу и распространение ПДн, за исключением случаев, прямо указанных в поручении на обработку персональных данных, необходимых для целей исполнения соответствующего договора с таким третьим лицом.</li>
            </ul>
            <ol start="7">
            <li><strong> Меры, принимаемые Оператором для защиты персональных данных</strong></li>
            </ol>
            <p>7.1. Оператор принимает необходимые и достаточные правовые, организационные и технические меры для защиты информации, предоставляемой Пользователями, от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц. Такие действия, в частности, включают:</p>
            <ul>
            <li>Назначение лица, ответственного за обработку ПДн;</li>
            <li>Применение организационных и технических мер по обеспечению безопасности ПДн при их обработке в информационных системах;</li>
            <li>Контроль фактов несанкционированного доступа к ПДн и принятие мер по недопущению подобных инцидентов в дальнейшем;</li>
            <li>Контроль за принимаемыми мерами по обеспечению безопасности ПДн и уровнем защищенности информационных систем ПДн.</li>
            </ul>
            <ol start="8">
            <li><strong> Права Пользователя</strong></li>
            </ol>
            <p>8.1. Пользователь принимает решение о предоставлении его ПДн и дает согласие на их обработку свободно, своей волей и в своем интересе. Пользователь выражает свое согласие на обработку ПДн в порядке, приведенном в п. 4.2 настоящей Политики.</p>
            <p>8.2. Пользователь имеет право на получение у Оператора информации, касающейся обработки его ПДн.</p>
            <p>8.3. Пользователь вправе направлять Оператору свои запросы и требования (далее - Обращение), в том числе относительно использования его ПДн, а также отзыва согласия на обработку ПДн. Обращение может быть направлено следующими способами:</p>
            <p>8.3.1. В письменной форме по адресу Оператора (раздел 11 Политики)</p>
            <p>8.3.2. В форме электронного документа (скан, фотокопия документа). Документ должен быть направлен с адреса электронной почты Пользователя, указанного им при регистрации на Платформе по адресу электронной почты: АДРЕС ПОЧТЫ ТЕХ-ПОДДЕРЖКИ.</p>
            <ol start="9">
            <li>Актуализация, исправление, удаление и уничтожение персональных данных</li>
            </ol>
            <p>9.1. Оператор обязуется сообщить Пользователю или его представителю в порядке, предусмотренном ст. 14 ФЗ-152, информацию о наличии ПДн, относящихся к этому Пользователю, а также предоставить возможность ознакомления с этими ПДн при обращении Пользователя или его представителя в течение 10 (десяти) дней с даты получения запроса Пользователя или его представителя.</p>
            <p>9.2. Предоставление &nbsp;Пользователю или его представителю возможности ознакомления с ПДн, относящимися к этому Пользователю осуществляется Оператором безвозмездно.</p>
            <p>9.3. В срок, не превышающий 7 (семи) рабочих дней со дня предоставления Пользователем или его представителем сведений, подтверждающих, что ПДн являются неполными, неточными или неактуальными, Оператор обязуется внести в них необходимые изменения.</p>
            <p>9.4. В срок, не превышающий 7 (семи) рабочих дней со дня представления Пользователем или его представителем сведений, подтверждающих, что такие ПДн являются незаконно полученными или не являются необходимыми для заявленной цели обработки, Оператор обязуется уничтожить такие ПДн.</p>
            <p>9.5. В случае подтверждения факта неточности ПДн Оператор на основании сведений, представленных Пользователем или его представителем либо уполномоченным органом по защите прав субъектов ПДн, обязуется уточнить ПДн либо обеспечить их уточнение (если обработка ПДн осуществляется другим лицом, действующим по поручению Оператора) в течение 7 (семи) рабочих дней со дня представления таких сведений и снять блокирование ПДн.</p>
            <p>9.6. Оператор обязуется прекратить обработку ПДн:</p>
            <ul>
            <li>В случае отзыва Пользователем согласия на обработку его ПДн;</li>
            <li>В случае достижения цели обработки ПДН.</li>
            </ul>
            <p>9.7. Оператор обязуется уничтожить ПДн Пользователя или обеспечить их уничтожение (если обработка ПДн осуществляется другим лицом, действующим по поручению ЧОУ ДПО) в срок, не превышающий 30 (тридцати) дней с даты достижения цели обработки ПДн.</p>
            <ol start="10">
            <li>Изменение политики</li>
            </ol>
            <p>10.1. Оператор оставляет за собой право вносить изменения в Политику. Пользователь обязан при каждом новом использовании Платформы ознакомиться с текстом Политики.</p>
            <p>10.2. Новая редакция Политики вступает в силу с момента ее размещения в соответствующем разделе Платформы. Продолжение пользования Платформой или ее сервисами после публикации новой редакции Политики означает принятие Политики и ее условий Пользователем. В случае несогласия с условиями Политики Пользователь должен немедленно прекратить использование Платформы и ее сервисов.</p>
            <p>&nbsp;</p>
            <p>ИП Нойманн-Зандер Наргиза Халиловна</p>
            <p>Юридический адрес: Россия, Челябинская область, г. Челябинск, пр. Ленина, 26А/2, Деловой центр &laquo;Бовид&raquo;, оф. 1008</p>
            <p>ОГРНИП:</p>
            <p>ИНН: 744844394931</p>
            <p>Платежные реквизиты:</p>
            <p>р/с: 40802810338090001390</p>
            <p>ФИЛИАЛ &laquo;ЕКАТЕРИНБУРГСКИЙ&raquo;</p>
            <p>АО &laquo;АЛЬФА-БАНК&raquo;</p>
            <p>БИК : 046577964</p>
            <p>к/с 30101810100000000964</p>
        </Container>
    </>
);
};

export default PublickOffert;