import React from 'react';
import { useLocation } from 'react-router-dom';
import {useEffect, useState} from 'react';
import { addLessonMaterial, getLessonMaterial, updateLesson } from '../http/deviceApi';
import { NavLink } from 'react-router-dom';


const AdminAddLessonMaterial = () => {
    const navigate = useLocation()

    const [lessonMaterial, setlessonMaterial] = useState([])

    const [LessonName, setLessonName] = useState(navigate.state.data_categories.name)

    
    const [file, setFile] = useState(null)

    useEffect(()=>{
        getLessonMaterial(navigate.state.data_categories.id).then(data =>{
            if(data === null){
            }else{
                setlessonMaterial(data)
            }
        })
    }, [])

    const addLessonMaterial_click = () =>{
        const formData = new FormData()
        formData.append('id_lesson', navigate.state.id_lesson)
        formData.append('url_material', file)
        addLessonMaterial(formData).finally(window.location.reload())
    }

    const update_lesson = () => {
        const result = window.confirm("Вы уверены что хотите обновить информацию об уроке: " + navigate.state.data_categories.name);
        if(result){
            const formData = new FormData()
            formData.append('id', navigate.state.data_categories.id)
            formData.append('name', LessonName)
            updateLesson(formData).finally(window.location.reload())
        }
    }


    return (
        <>
            <h3>{navigate.state.data_categories.name}</h3>
            <nav className='row'>
                <nav className='col-12 col-md-6'>
                    <span>Название категории</span>
                    <input className='input-admin-module' type='text' defaultValue={LessonName} onChange={(e)=>setLessonName(e.target.value)}/>
                </nav>
            </nav>
            <button className='btn-update-curse-info' onClick={update_lesson}>Сохранить</button>
            <nav className='row'>
                <nav className='col-12 col-md-6'>
                    <span>Выберите файл</span>
                    <p>Актуальный файл: {lessonMaterial.url_material}</p>
                    <input className='input-admin-module' type='file' onChange={(e)=> setFile(e.target.files[0])}/>
                </nav>
            </nav>
            <button className='btn-update-curse-info' onClick={addLessonMaterial_click}> Установить материал </button>
        </>
    );
};

export default AdminAddLessonMaterial;