import React from 'react';
import { NavLink } from 'react-router-dom';

import logotype from '../assets/logo-vap-menu-header.png'
import { POLITIC_CONF, PUBLICK_OFFERT } from '../routes/const';

const Footer = () => {
    return (
        <div>
            <nav className="container-fluid">
                <footer>
                    <nav className="row">
                        <nav className="col-12 col-lg-2">
                            <img className="logo-menu-footer" src={logotype} alt="logo"/>
                        </nav>
                        <nav className="col-12 col-lg-4">
                            <p className="footer-ip-text">ИП Нойманн-Зандер Н.Х., ИНН 744844394931,ОГРНИП 318745600168289, РФ, пр. Ленина, 26а/2, офис 1103, Челябинск, Челябинская обл., 454091</p>
                        </nav>
                        <nav className="col-12 col-lg-3  offset-lg-3">
                            <NavLink to={POLITIC_CONF}><p className="text-footer-public">Политика конфиденциальности</p></NavLink>
                        </nav>
                    </nav>
                </footer>
            </nav>
        </div>
    );
};

export default Footer;