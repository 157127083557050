import {makeAutoObservable} from 'mobx'

export default class CourseStore{
    constructor(){
        this._courses = {}
        this._modules = {}
        this._categories = {}
        this._lessons = {}
        makeAutoObservable(this)
    }

    setCourses(courses){
        this._courses = courses
    }

    setModules(modules){
        this._modules = modules
    }

    setCategories(categories){
        this._categories = categories
    }

    setLessons(lessons){
        this._lessons = lessons
    }
    

    get getCourses(){
        return this._courses
    }

    get getModules(){
        return this._modules
    }

    get getCategories(){
        return this._categories
    }

    get getLessons(){
        return this._lessons
    }
}