import { observer } from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom'

import succ100 from '../assets/svg/succ-100.svg'
import { CHECK_MODULE } from '../routes/const';

import {fetchDevices} from "../http/deviceApi";


const CoursesList = observer(() => {
    

    const history = useNavigate()

    const [activeType, setActiveType] = useState([])

    useEffect(() => {
        fetchDevices().then(data => { 
            setActiveType(data)
        })
    }, [])
    
    return (
        <>
        {
            activeType.map(type => 
                <nav key={type.id} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                    <nav className="material-block-solo-out">
                        <p className="material-block-solo-category-name">Категория</p>
                        <p className="material-block-solo-course-name">{type.name}</p>
                        <p className="material-block-solo-material-count" onClick={() => history(CHECK_MODULE + '/' + type.id)} style={{"cursor":"pointer"}}>Перейти к изучению</p>
                        <nav className="row">
                            <nav className="col-6">
                                <p className="material-block-solo-author-name">{type.author}</p>
                            </nav>
                            <nav className="col-6">
                                <p className="material-block-solo-percent-end">Завершено 100% <img src={succ100} alt="status-img"/></p>
                            </nav>
                        </nav>
                    </nav>
                </nav>
            )
        }
        </>
    );
});

export default CoursesList;