import { observer } from 'mobx-react-lite';
import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import CoursesList from '../components/CoursesList'
import './CoursesPage.css'


const CoursesPage = observer(() => {

    return (
        <>
            <Header />
            <div>
                <nav className="container-fluid">
                    <h3>Доступные материалы</h3>
                    <nav className="row">
                        <CoursesList />
                    </nav>
                </nav>  
            </div>
            <Footer />
        </>
    );
});

export default CoursesPage;