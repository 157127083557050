export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/'
export const REGISTRATION_ROUTE = '/registration'
export const RESET_PASSWORD = '/reset-password'
export const COURSES_ROUTE = '/'
export const CHECK_MODULE = '/module'
export const SETTINGS_ROUTE = '/settings'
export const CHANGEPASSWORD_ROUTE = 'changepassword'
export const ADMIN_PANEL_ROUTE = '/panel'
export const PUBLICK_OFFERT = '/politics'
export const POLITIC_CONF = '/confederacy'