import {  createBrowserRouter } from "react-router-dom";
import { LOGIN_ROUTE, REGISTRATION_ROUTE,RESET_PASSWORD, COURSES_ROUTE, SETTINGS_ROUTE,CHANGEPASSWORD_ROUTE, CHECK_MODULE, ADMIN_PANEL_ROUTE, PUBLICK_OFFERT, POLITIC_CONF } from '../routes/const';

import LoginPage from '../pages/LoginPage';
import CoursesPage from '../pages/CoursesPage';
import SettingsPage from '../pages/SettingsPage';
import CheckModule from "../pages/CheckModule";
import SettingsChangePassword from '../modules/SettingsChangePassword'
import WatchCourse from '../pages/WatchCourse'
import WatchLesson from "../pages/WatchLesson";
import AdminPanel from "../pages/AdminPanel";


import AdminAddCourse from "../modules/AdminAddCourse"
import AdminAddModules from "../modules/AdminAddModules"
import AdminAddLessonCategories from "../modules/AdminAddLessonCategories"
import AdminAddLessonList from "../modules/AdminAddLessonList"
import AdminAddLessonMaterial from "../modules/AdminAddLessonMaterial"
import AdminUserPage from "../modules/AdminUserPage";
import ResetPassword from "../pages/ResetPassword";
import PublickOffert from "../pages/PublickOffert";
import PoliticConfedicial from "../pages/PoliticConfedicial";
import AdminDashboard from "../modules/AdminDashboard";


export const router = createBrowserRouter([
    {
      path: COURSES_ROUTE,
      element: <CoursesPage />,
      errorElement: <CoursesPage />
    },
    {
      path: CHECK_MODULE + '/:id',
      element: <CheckModule />,
      errorElement: <CoursesPage />,
      children: [
        {
          path: ':name',
          element: <WatchCourse />,
        },
        {
          path: ':name/:lesson',
          element: <WatchLesson />,
        }
      ],
    },
    {
      path: SETTINGS_ROUTE,
      element: <SettingsPage />,
      errorElement: <CoursesPage />,
      children: [
        {
          path: CHANGEPASSWORD_ROUTE,
          element: <SettingsChangePassword />
        },
      ],
    },
    {
      path: ADMIN_PANEL_ROUTE,
      element: <AdminPanel />,
      errorElement: <CoursesPage />,
      children: [
        {
          path: '',
          element: <AdminDashboard />
        },
        {
          path: 'users',
          element: <AdminUserPage />
        },
        {
          path: 'course',
          element: <AdminAddCourse />
        },
        {
          path: 'course/:id',
          element: <AdminAddModules />
        },
        {
          path: 'course/:id/:module',
          element: <AdminAddLessonCategories />
        },
        {
          path: 'course/:id/:module/:lessoncat',
          element: <AdminAddLessonList />
        },
        {
          path: 'course/:id/:module/:lessoncat/:lesson',
          element: <AdminAddLessonMaterial />
        },
      ],
    },
    {
      path: POLITIC_CONF,
      element: <PoliticConfedicial />
    }
  ]);

export const router_auth = createBrowserRouter([
    {
      path: LOGIN_ROUTE,
      element: <LoginPage />,
      errorElement: <LoginPage />
    },
    {
      path: REGISTRATION_ROUTE,
      element: <LoginPage />
    },
    {
      path: RESET_PASSWORD,
      element: <ResetPassword />
    },
    {
      path: PUBLICK_OFFERT,
      element: <PublickOffert />
    }
  ]);