import React from 'react';
import {useEffect, useState} from 'react';
import { deleteCourse, getCoursesAdmin, addCourse } from '../http/deviceApi';
import { NavLink } from 'react-router-dom';

const AdminAddCourse = () => {

    const [coursesList, setCoursesList] = useState([])
    const [addNewCourse, setaddNewCourse] = useState(false)

    const [nameCourse, setnameCourse] = useState('')
    const [authorCourse, setauthorCourse] = useState('')

    useEffect(()=>{
        getCoursesAdmin().then(data =>{
            setCoursesList(data)
        })
    }, [])

    const click = async () => {
        try {
            let data;
            data = await addCourse(nameCourse, authorCourse);

            if(data){
                window.location.reload();
            }else{
                alert('Ошибка при создании курса')
            }
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const delete_course = (id) => {
        let name_course = ""
        for( let i = 0; i < coursesList.length; i++){
            if(coursesList[i].id === id){
                name_course = coursesList[i].name
                break
            }
        }
        const result = window.confirm("Вы уверены что хотите удалить курс: " + name_course);
        if(result){
            deleteCourse(id).finally(window.location.reload())
        }
        //const formData = new FormData()
        //formData.append('id_lesson', navigate.state.id_lesson)
        //addLessonMaterial(formData).then(alert('Материал успешно установлен'))
    }

    return (
        <>
            <nav className='row'>
                <nav className='col-12 col-md-12'>
                    <nav className='row'>
                        <nav className='col-12 col-md-6'>
                            <h3 className='title-course-page-edit'>Выбрать курс</h3>
                        </nav>
                        <nav className='col-12 col-md-6'>
                            <button className='btn-add-new-course' onClick={() => setaddNewCourse(!addNewCourse)}>{addNewCourse ? 'Отменить' : 'Создать новый курс'}</button>
                        </nav>
                    </nav>
                    {
                        addNewCourse === false ? 
                        <table className="iksweb">
                            <tbody>
                                <tr>
                                    <th>Название курса</th>
                                    <th>Автор</th>
                                    <th>Дата создания</th>
                                    <th>Статус</th>
                                    <th>#</th>
                                </tr>
                                {
                                    coursesList.map(course => 
                                        <tr key={course.id}>
                                            <td><NavLink to={'./'+course.id} state={{name: course.name, id_course: course.id, data_course: course}}>{course.name}</NavLink></td>
                                            <td>{course.author}</td>
                                            <td>{course.createdAt.split('T')[0]}</td>
                                            <td><span style={{color: '#55C839'}}>Опубликован</span></td>
                                            <td style={{cursor: "pointer"}} onClick={() => delete_course(course.id)}>Удалить</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        :
                        <>
                            <h4>Создание курса</h4>
                            <nav className='row'>
                                <nav className='col-12 col-md-6'>
                                    <span>Название курса</span>
                                    <input className='input-admin-module' type='text' onChange={(e)=>setnameCourse(e.target.value)}/>
                                </nav>
                                <nav className='col-12 col-md-6'>
                                    <span>Автор курса</span>
                                    <input className='input-admin-module' type='text' onChange={(e)=>setauthorCourse(e.target.value)}/>
                                </nav>
                            </nav>
                            <button onClick={click} className='btn-update-curse-info'>Создать курс</button>
                        </>
                    }
                    
                </nav>
            </nav>
        </>
    );
};

export default AdminAddCourse;